import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import logo from "../assets/autohall.png";
import Button from "../components/elements/Button";
import CustomInput from "../components/elements/CustomInput";
import ThemeContext from "../contexts/ThemeContext";
import AdminContext from "../contexts/AdminContext";

const Login = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  const { setTheme } = useContext(ThemeContext);
  const { setAdmin } = useContext(AdminContext);
  const { REACT_APP_BACKEND_URL, REACT_APP_SECRET } = process.env;

  useEffect(() => {
    const admin = localStorage.getItem("admin");

    jwt.verify(admin, REACT_APP_SECRET, async (err, decoded) => {
      if (decoded) {
        history.push("/");
      }
    });
  }, []);

  const login = async (email, pwd) => {
    setError(null);
    if (email && pwd) {
      try {
        const response = await fetch(REACT_APP_BACKEND_URL + "/admin/login", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email,
            pwd,
          }),
        });

        const res = await response.json();
        if (res.token) {
          localStorage.setItem("admin", res.token);
          jwt.verify(res.token, REACT_APP_SECRET, async (err, decoded) => {
            if (decoded) {
              try {
                const response = await fetch(
                  REACT_APP_BACKEND_URL + "/brand/" + decoded.brand,
                  {
                    method: "GET",
                    headers: {
                      "x-access-token": res.token,
                      "Content-Type": "application/json",
                    },
                  }
                );

                const result = await response.json();
                if (result) {
                  const obj = {
                    id: result.brand_id,
                    color: result.brand_color,
                    logo: result.brand_img,
                    name: result.brand_name,
                    slogan: result.brand_slogan,
                  };
                  localStorage.setItem("theme", JSON.stringify(obj));
                  setTheme(obj);
                  setAdmin(decoded);

                  if (decoded.role?.includes("Clients")) {
                    history.push("/clients");
                  } else if (decoded.role?.includes("Simulateur")) {
                    history.push("/simulations");
                  } else if (decoded.role?.includes("Rendez-vous")) {
                    history.push("/rvs");
                  } else if (decoded.role?.includes("Réclamations")) {
                    history.push("/complaints");
                  } else {
                    history.push("/");
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }
          });
        } else {
          setError(res.msg);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Container>
      <img src={logo} alt="logo" />
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Adresse e-mail invalide")
            .required("Champs Obligatoire"),
          password: Yup.string().required("Champs Obligatoire"),
        })}
        onSubmit={async (data) => {
          setLoading(true);
          await login(data.email, data.password);
          setLoading(false);
        }}
      >
        {() => (
          <Form className="form">
            <CustomInput
              label="Email"
              name="email"
              id="email"
              type="email"
              placeholder="Entrer Votre Email"
            />
            <CustomInput
              label="Password"
              name="password"
              id="password"
              type="password"
              placeholder="Entrer Votre Password"
            />
            <p className="error">{error}</p>
            <Button
              title={loading ? "Connecter..." : "Connecter"}
              width="large"
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  background-color: rgb(247, 247, 249);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  form {
    margin-top: 0.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  input {
    width: 320px;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
    text-align: left;
    width: 100%;
  }
  img {
    max-width: 140px;
  }
  button {
    background: #222;
    border: 2px solid #222;
    &:hover {
      color: #222;
    }
  }
`;
