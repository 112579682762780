import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Button from "../components/elements/Button";
import Layout from "../layouts/DefaultLayout";
import ThemeContext from "../contexts/ThemeContext";
import AdminContext from "../contexts/AdminContext";
import FeedBack from "../components/elements/FeedBack";

const Brand = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const [isSubmitting, setIsSubmitting] = useState(0);
  const [color, setColor] = useState(theme?.color);
  const [logo, setLogo] = useState(null);
  const [feedback, setFeedBack] = useState({ status: null, message: null });
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");
  const { admin } = useContext(AdminContext);
  const history = useHistory();

  const watchColor = (e) => {
    setColor(e.target.value);
  };

  const uploadLogo = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = async () => {
        const height = image.height;
        const width = image.width;
        // if (height > 35 && height < 60 && width > 90 && width < 200) {
        try {
          const response = await fetch(REACT_APP_BACKEND_URL + "/upload", {
            method: "POST",
            body: formData,
          });
          const res = await response.json();

          if (res.path) {
            setLogo("/" + res.path);
          }
        } catch (err) {
          console.log(err);
        }
        // } else {
        //   alert("Dimensions de l'image non valides");
        //   return false;
        // }
      };
    };
  };

  const submit = async () => {
    setIsSubmitting(1);
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/brand/" + theme.id,
        {
          method: "PUT",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            color,
            logo: logo,
          }),
        }
      );
      const res = await response.json();
      if (res.message) {
        setFeedBack({
          ...feedback,
          status: true,
          message: "Charte graphique modifier avec Succès!",
        });
        setTimeout(() => {
          setFeedBack({
            ...feedback,
            status: false,
            message: null,
          });
        }, 2000);

        if (logo !== null) {
          setTheme((prev) => ({
            ...prev,
            color,
            logo,
          }));
          localStorage.setItem(
            "theme",
            JSON.stringify({ ...theme, color, logo })
          );
        } else {
          setTheme((prev) => ({
            ...prev,
            color,
          }));
          localStorage.setItem("theme", JSON.stringify({ ...theme, color }));
        }
        setIsSubmitting(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (admin?.role?.includes("Clients")) {
      history.push("/clients");
    } else if (admin?.role?.includes("Simulateur")) {
      history.push("/simulations");
    } else if (admin?.role?.includes("Rendez-vous")) {
      history.push("/rvs");
    } else if (admin?.role?.includes("Réclamations")) {
      history.push("/complaints");
    } else if (admin?.role?.includes("Leads")) {
      history.push("/");
    }
  }, [admin]);
  useEffect(() => {
    console.log(theme);
  }, []);

  return (
    <Layout>
      <Container color={theme?.color}>
        <div className="wrp">
          <h4>Chartes graphique</h4>
          <div className="row">
            <label htmlFor="marque">Nom:</label>
            <span>{theme?.name}</span>
          </div>
          <div className="row">
            <label htmlFor="color">Coleur:</label>
            <input type="color" value={color} onChange={watchColor} />
            <span className="colorText">{color}</span>
          </div>
          <div className="logo-input row">
            <label htmlFor="imageUploader">
              Logo:<span className="dim"> (100*50)</span>
            </label>
            <label className="imgUpLabel" htmlFor="imageUploader">
              Choisir
            </label>
            <input
              id="imageUploader"
              className="imageUploader"
              type="file"
              accept="image/png, image/jpeg "
              placeholder="Choisissez le logo"
              style={{ display: "none" }}
              onChange={(e) => uploadLogo(e.target.files[0])}
            />
          </div>
          {logo !== null ? (
            <img src={REACT_APP_BACKEND_URL + logo} alt="logo" />
          ) : (
            <img
              src={REACT_APP_BACKEND_URL + theme?.logo}
              alt="logo"
              className="logo"
            />
          )}

          <Button
            handleClick={() => submit()}
            title={isSubmitting ? "Enregister..." : "Enregister"}
            type="submit"
            radius="7px"
          />
        </div>
        {feedback.status ? (
          <FeedBack text={feedback.message} status={2} />
        ) : null}
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 2em;
  .wrp {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 200px;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin: 0.5em 0em 2em 0;
    width: 200px;
  }
  select {
    padding: 8px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    min-width: 260px;
    margin-bottom: 0.35em;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
    width: 110px;
  }
  span {
    font-size: 15px;
    font-weight: 600;
    color: #111;
  }
  .dim {
    font-size: 12px;
    font-weight: 600;
    color: #999;
  }
  .row {
    display: flex;
    align-items: center;
    padding: 0.75em 0;
    width: 240px;
  }
  .imgUpLabel {
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    background: ${(props) => props.color || "#2d4185"};
    color: #fff;
    padding: 4px 18px;
    width: 90px;
    border-radius: 7px;
    border: 2px solid ${(props) => props.color || "#2d4185"};
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background: transparent;
      color: ${(props) => props.color || "#2d4185"};
    }
  }
  .imageUploader {
    display: none !important;
  }
  img {
    max-width: 180px;
    width: auto;
    border-radius: 7px;
    margin-top: 2em;
  }
  input[type="color"] {
    border: none;
    background-color: transparent;
    height: 30px;
    outline: none;
    border-radius: 12px;
    position: relative;
  }
  .colorText {
    background: #f1f1f1;
    padding: 0 5px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #808080;
    font-size: 12px;
    height: 25px;
    display: flex;
    align-items: center;
  }
`;

export default Brand;
