import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SearchBar from "../components/SearchBar";
import Layout from "../layouts/DefaultLayout";
import AdminContext from "../contexts/AdminContext";
import Pagination from "../components/Pagination";
import DeletePopUp from "../components/PopUps/DeletePopup";

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [initialAdmins, setInitialAdmins] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");
  const history = useHistory();
  const { admin } = useContext(AdminContext);
  const [total, setTotal] = useState(1);
  const [popup, setPopup] = useState(0);
  const [currentPage, setCurrrentPage] = useState(1);
  const postsPerPage = 12;

  const getAdmins = async (page) => {
    const offest = (page - 1) * postsPerPage;

    try {
      const response1 = await fetch(
        REACT_APP_BACKEND_URL + `/adminsTotal/${admin.brand}`,
        {
          method: "GET",
        }
      );

      const res1 = await response1.json();
      setTotal(res1.total);

      const response = await fetch(
        REACT_APP_BACKEND_URL +
          `/adminByBrand/${admin.brand}/${postsPerPage}/${offest}`,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setAdmins(res);
      setInitialAdmins(res);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAdmin = async (id) => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/admin/" + id, {
        method: "DELETE",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      if (res.message) {
        alert("L'Admin a été supprimer avec succès");
        const arr = initialAdmins.filter((item) => item.admin_id !== id);
        setAdmins(arr);
        setInitialAdmins(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (admin?.role?.includes("Clients")) {
      history.push("/clients");
    } else if (admin?.role?.includes("Simulateur")) {
      history.push("/simulations");
    } else if (admin?.role?.includes("Rendez-vous")) {
      history.push("/rvs");
    } else if (admin?.role?.includes("Réclamations")) {
      history.push("/complaints");
    } else if (admin?.role?.includes("Leads")) {
      history.push("/");
    }
  }, [admin]);

  useEffect(() => {
    if (admin.brand) {
      getAdmins(1);
    }
  }, [admin]);

  return (
    <Layout>
      <Container>
        <SearchBar
          title="Liste des Admins"
          link="/add-admin"
          btnText="Ajouter"
          state={initialAdmins}
          setState={setAdmins}
          component="admins"
        />
        <div className="table">
          <div className="grid">
            <h6>Prénom Nom</h6>
            <h6>Email</h6>
            <h6>Role</h6>
            <h6 />
            {admins.length !== 0 ? (
              admins.map((admin, index) => (
                <Fragment key={index}>
                  <h5 className="name">{admin.admin_username}</h5>
                  <h5>{admin.admin_email}</h5>
                  <h5>{admin.admin_role}</h5>
                  <h5 className="icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="#f84b60"
                      onClick={() => setPopup(admin.admin_id)}
                    >
                      <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      className="view"
                      fill="#777"
                      onClick={() =>
                        history.push({
                          pathname: "/edit-admin",
                          state: admin.admin_id,
                        })
                      }
                    >
                      <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z" />
                    </svg>
                  </h5>
                </Fragment>
              ))
            ) : (
              <div className="not-found">
                <p>
                  <span className="red">*</span>pas de résultat
                </p>
              </div>
            )}
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            paginate={getAdmins}
            setCurrrentPage={setCurrrentPage}
            totalPosts={total}
            currentPage={currentPage}
          />
          {popup ? (
            <DeletePopUp
              deleteItem={() => deleteAdmin(popup)}
              cancleOperation={() => setPopup(0)}
              target="Supprimer compte Admin"
              message="êtes-vous sûr de vouloir supprimer ce compte admin"
            />
          ) : null}
        </div>
      </Container>
    </Layout>
  );
};

export default Admins;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    color: #726e6e;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    font-weight: 600;
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 0.25fr;
    grid-template-rows: auto;
  }
  .btn {
    background: #f84b60;
    color: #fff;
    padding: 5px 7px;
    border-radius: 7px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    justify-content: center;
    cursor: pointer;
  }
  .green {
    background: #56e78e;
  }
  .icons {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
  .not-found {
    padding: 1em;
    display: flex;
    align-items: center;
    p {
      color: #aaa;
    }
    .red {
      color: red;
      margin: 0 1em;
    }
  }
`;
