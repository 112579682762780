import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import CustomInput from "../components/elements/CustomInput";
import Layout from "../layouts/DefaultLayout";
import Button from "../components/elements/Button";
import StyledAddInput from "../components/elements/StyledAddInput";
import ViewElements from "../components/elements/ViewElements";
import AdminContext from "../contexts/AdminContext";
import ThemeContext from "../contexts/ThemeContext";

const AddSite = () => {
  const staticTags = [
    "ville",
    "email",
    "nom",
    "marque",
    "origine",
    "plateforme",
    "modele",
    "telephone",
    "campagne",
  ];
  const [tags, settags] = useState([]);
  const [tag, settag] = useState();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [empty, setEmpty] = useState({ first: false, second: false });
  const history = useHistory();
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  const { admin } = useContext(AdminContext);

  const deleteSpanTag = (tag) => {
    settags(tags.filter((item) => item !== tag));
  };

  const deleteEmail = (email) => {
    setEmails(emails.filter((item) => item !== email));
  };

  const addtags = () => {
    if (!staticTags.includes(tag)) {
      settags([...tags, tag]);
    } else {
      setError("Champ existe déja");
    }
  };

  const addEmails = () => {
    setEmails([...emails, email]);
  };

  const SendEmails = async (emails, source, tags) => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/sendLeads`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          source,
          tags,
          emails,
        }),
      });
      const responseJson = await response.json();
      console.log(responseJson);
    } catch (e) {
      console.log(e.message);
    }
  };

  const addSite = async (data) => {
    console.log(data);
    const newtags = [...staticTags, ...tags];
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/leads/add-site", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          source: data.source,
          tags: newtags,
          emails,
        }),
      });
      const res = await response.json();
      if (res) {
        history.push("/leads-sites");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (admin?.role?.includes("Clients") && !admin?.role?.includes("Leads")) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/complaints");
    }
  }, [admin]);

  return (
    <Layout>
      <Container color={theme?.color}>
        <Formik
          initialValues={{
            source: "",
          }}
          validationSchema={Yup.object({
            source: Yup.string().required("Champs Obligatoire"),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            tags.length === 0
              ? setEmpty({ ...empty, first: true })
              : setEmpty({ ...empty, first: false });
            emails.length === 0
              ? setEmpty({ ...empty, second: true })
              : setEmpty({ ...empty, second: false });
            if (emails.length !== 0) {
              await addSite({ ...data, emails, tags });
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form className="form">
              <h3 className="border">Ajouter un site Form</h3>
              <div className="sub-form">
                <CustomInput
                  margin="0 0.5em"
                  label="Source name"
                  name="source"
                  id="source"
                  type="text"
                />
                <ViewElements
                  title="Chapms static"
                  values={staticTags}
                  strict
                />
                <StyledAddInput
                  margin="0 0.5em"
                  label="Ajouter un champ"
                  name="champ"
                  type="champ"
                  value={tag}
                  setAction={settag}
                  handleClick={addtags}
                  color={theme?.color}
                />
                <ViewElements
                  title="Champs list"
                  values={tags}
                  handleClick={(tag) => deleteSpanTag(tag)}
                />
                {error ? <p className="empty">{error}</p> : null}
                {empty.first ? (
                  <p className="empty">Champs Obligatoire</p>
                ) : null}
                <StyledAddInput
                  margin="0 0.5em"
                  label="Ajouter un email"
                  name="email"
                  id="email"
                  type="email"
                  value={email}
                  setAction={setEmail}
                  handleClick={addEmails}
                  color={theme?.color}
                />
                <ViewElements
                  title="Champs list"
                  values={emails}
                  handleClick={(email) => deleteEmail(email)}
                />
                {empty.second ? (
                  <p className="empty">Champs Obligatoire</p>
                ) : null}
                <div className="btn-wrp">
                  <Button
                    handleClick={handleSubmit}
                    title={isSubmitting ? "Ajouter..." : "Ajouter"}
                    type="submit"
                    radius="7px"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default AddSite;
const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  width: 100%;
  max-width: 600px;
  margin: 3em auto;
  padding: 1em 0 2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #f84b60;
    }
  }
  .avatar:hover + span {
    display: block;
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -95px;
    right: 122px;
    top: 40px;
    display: none;
    &:before {
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    object-fit: cover;
  }
  .file-label {
    margin: 0 auto;
    position: relative;
    margin-top: 1em;
    align-self: center;
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-name {
    font-size: 18px;
    font-weight: 600;
    display: none;
    padding-top: 0.75em;
    margin-left: 0.25em;
    margin-bottom: 1.25em;
  }
  h3 {
    width: 100%;
    color: #868e96 !important;
    font-weight: 500;
    font-size: 16px;
    padding: 0 1em;
  }
  .border {
    border-bottom: 1px solid #e1e5eb !important;
    padding: 0.8em 1em;
  }
  form {
    width: 100%;
  }
  input {
    width: 100%;
  }
  .sub-form {
    padding: 0 1em;
  }
  .empty {
    font-size: 11px;
    color: red;
    font-weight: 600;
    margin-top: 0.1em;
    margin-left: 0.2em;
  }
`;
