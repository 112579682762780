import React from "react";
import styled from "styled-components";

const CustomPopUp = ({ children, bg, opacity, color }) => {
  return (
    <Container bg={bg} opacity={opacity} color={color}>
      {children}
    </Container>
  );
};

export default CustomPopUp;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  margin: 0;
`;
