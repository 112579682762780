import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import LeadsHeader from "../components/leads/LeadsHeader";
import AdminContext from "../contexts/AdminContext";
import FeedBack from "../components/elements/FeedBack";
import DeletePopUp from "../components/PopUps/DeletePopup";

const SitesConfig = () => {
  const [isDeleted, setIsDeleted] = useState(0);
  const [searchArray, setSearchArray] = useState([]);
  const [siteStructure, setSiteStructure] = useState([]);
  const [popup, setPopUp] = useState(false);
  const { admin } = useContext(AdminContext);
  const { REACT_APP_BACKEND_URL } = process.env;
  const history = useHistory();

  const getSitesConfig = async () => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/leads/get-site-structure",
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      setSearchArray(res);
      setSiteStructure(res);
    } catch (err) {
      console.log(err);
    }
  };

  const deleatSite = async (id) => {
    setIsDeleted(1);
    let newArray = [];
    newArray = siteStructure.filter((item) => {
      return item._id !== id;
    });
    setSiteStructure(newArray);
    setSearchArray(newArray);

    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/leads/delete-site/${id}`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      if (res) {
        setIsDeleted(2);
        setTimeout(() => {
          setIsDeleted(0);
        }, 300);
      }
    } catch (err) {
      console.log(err.message);
      setIsDeleted(-1);
      setTimeout(() => {
        setIsDeleted(0);
      }, 300);
    }
  };

  useEffect(() => {
    getSitesConfig();
  }, []);

  useEffect(() => {
    if (admin?.role?.includes("Clients") && !admin?.role?.includes("Leads")) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/complaints");
    } else if (admin?.role?.includes("Leads")) {
      history.push("/");
    }
  }, [admin]);

  return (
    <Layout>
      <Container>
        <LeadsHeader
          title="Site Spécialisée"
          btnText="Ajouter"
          handlClick={() => null}
          state={searchArray}
          setState={setSiteStructure}
          parent="sitesconfig"
          link="/add-site"
        />
        <div className="table">
          <div className="grid">
            <div className="row row-top">
              <h6>Site</h6>
              <h6>Champs</h6>
              <h6>Destination Emails</h6>
              <h6 />
            </div>
            {siteStructure.length !== 0 ? (
              siteStructure.map((item, index) => {
                return (
                  <div className="row row-border" key={`__${index}`}>
                    <h5 className="name">{item.source}</h5>
                    <div className="span-wrp">
                      {item.tags.map((child, index) => {
                        return (
                          <span className="span-data" key={`_${index}`}>
                            {child}
                          </span>
                        );
                      })}
                    </div>
                    <div className="span-wrp">
                      {Object.prototype.hasOwnProperty.call(item, "emails") ? (
                        item.emails.map((email, index) => {
                          return (
                            <span className="span-data" key={`email-${index}`}>
                              {email}
                            </span>
                          );
                        })
                      ) : (
                        <p className="no-result-msg">Nan</p>
                      )}
                    </div>
                    <h5 className="delete">
                      <Link
                        to={{
                          pathname: "view-sites",
                          state: { item },
                        }}
                      >
                        <button className="svg-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="#187bcd"
                            className="view"
                          >
                            <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z" />
                          </svg>
                        </button>
                      </Link>
                      <Link
                        to={{
                          pathname: "edit-sites",
                          state: { item },
                        }}
                      >
                        <button className="svg-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            className="edit"
                          >
                            <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z" />
                          </svg>
                        </button>
                      </Link>
                      <button
                        className="svg-btn"
                        onClick={() => setPopUp(item._id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="#f84b60"
                        >
                          <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                        </svg>
                      </button>
                    </h5>
                    {popup ? (
                      <DeletePopUp
                        deleteItem={() => {
                          deleatSite(popup);
                          setPopUp(false);
                        }}
                        cancleOperation={() => setPopUp(false)}
                        target="Supprimer compte Client"
                        message="êtes-vous sûr de vouloir supprimer ce client"
                      />
                    ) : null}
                  </div>
                );
              })
            ) : (
              <p className="no-result-msg">
                <span className="red">*</span>pas de resltat trouver
              </p>
            )}
          </div>
        </div>
        {isDeleted !== 0 ? (
          <FeedBack
            text={
              isDeleted === 1
                ? "suppression du site"
                : isDeleted === 2
                ? "Site supprimer"
                : "Une erreur est survenue"
            }
            status={isDeleted}
          />
        ) : null}
      </Container>
    </Layout>
  );
};
export default SitesConfig;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em;
  h5 {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
  }
  .no-result-msg {
    color: #808080;
    font-size: 13px;
    margin: 0 auto 0.25em 1em;
    padding: 0.5em;
    .red {
      color: red;
      margin: 0 0.25em;
    }
  }
  .table {
    overflow-x: auto;
    .grid {
      min-width: 1000px;
    }
  }
  .row {
    display: grid;
    grid-template-columns: 0.75fr 3fr 2fr 150px;
  }
  .row-top {
    border-bottom: 2px solid #dfe0eb;
    h6 {
      color: #676767;
    }
  }
  .row-border {
    border-bottom: 1px solid #dfe0eb;
    padding: 0.25em 0;
  }
  .name {
    color: #252733;
    font-weight: 600 !important;
  }
  .svg-btn {
    background-color: transparent;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      //fill: #f84b60;
      cursor: pointer;
      margin: 10px;
    }
  }
  .edit {
    fill: rgb(119, 119, 119) !important;
  }
  .span-wrp {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    overflow-y: scroll;
    height: 50px;
    .span-data {
      padding: 0.25em 0.5em;
      color: #9fa2b4;
      border-radius: 7px;
      background-color: #efefef;
      margin: 0.5em 0.25em;
      width: max-content;
    }
  }
`;
