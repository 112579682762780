
import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import styled from "styled-components";
import Button from "../components/elements/Button";
import Layout from "../layouts/DefaultLayout";
import CustomInput from "../components/elements/CustomInput";
import Photo from "../assets/avatar.png";
import AdminContext from "../contexts/AdminContext";
import SuccessPopUp from "../components/PopUps/SuccessPopup";
import CityList from "../utils/Citys";

const UpdateClient = () => {
  const [uploading, setUploading] = useState(0);
  const [image, setImage] = useState(null);
  const [popup, setPopup] = useState(0);
  const [city, setCity] = useState("");
  const [data, setData] = useState(null);
  const [profession, setProfession] = useState("");
  const [clientCategories, setClientCategories] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  const history = useHistory();
  const token = localStorage.getItem("admin");
  const { admin } = useContext(AdminContext);
  const location = useLocation();

  const uploadAvatar = async (file) => {
    setUploading(1);
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/upload", {
        method: "POST",
        body: formData,
      });
      const res = await response.json();

      if (res.path) {
        setImage(REACT_APP_BACKEND_URL + "/" + res.path);
        setUploading(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getClient = async () => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/client/" + location.state,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      res.user_birthdate = new Date(res.user_birthdate).toLocaleDateString(
        "fr-CA"
      );
      setProfession(res.user_profession);
      setCity(res.user_city);
      setData(res);
      setImage(res.user_profile);
      console.log("Hhhhhh", res);
    } catch (err) {
      console.log(err);
    }
  };

  const getClientCategories = async () => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/clientCategories",
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setClientCategories(res);
    } catch (err) {
      console.log(err);
    }
  };
  const updateClient = async (dt) => {
    try {
      const secondRes = await fetch(
        REACT_APP_BACKEND_URL + "/client/" + location.state,
        {
          method: "PUT",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...dt,
            username: dt.fname + " " + dt.lname,
            profile:
              image !== null ? image.replace(REACT_APP_BACKEND_URL, "") : null,
          }),
        }
      );

      const res = await secondRes.json();
      if (res.message) {
        setPopup(1);
        setTimeout(() => {
          setPopup(0);
          history.push("/clients");
        }, 1500);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Clients")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Clients")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Clients")
    ) {
      history.push("/complaints");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Clients")
    ) {
      history.push("/");
    }
  }, [admin]);

  useEffect(() => {
    getClient();
    getClientCategories();
  }, []);

  return (
    <Layout>
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            IDClient: data?.Custno,
            email: data?.user_email || "",
            pwd: "",
            fname: data?.user_fname || "",
            lname: data?.user_lname || "",
            birthdate: data?.user_birthdate || "",
            phone: data?.user_phone || "",
            nationality: data?.user_nationality || "",
            cin: data?.user_cin || "",
            address: data?.user_address || "",
            city: city,
            postcode: data?.user_postcode || "",
            profession: data?.user_profession || profession,
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Email invalid")
              .required("Champs Obligatoire"),
            pwd: Yup.string().min(6, "Doit comporter plus de 6 caractères"),
            fname: Yup.string().required("Champs Obligatoire"),
            lname: Yup.string().required("Champs Obligatoire"),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            await updateClient(data);
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form className="form">
              <h3 className="profil">Profil</h3>
              <label htmlFor="file-input" className="file-label">
                {uploading ? (
                  <div className="loader" title="2">
                    <svg
                      version="1.1"
                      id="loader-1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="40px"
                      height="40px"
                      viewBox="0 0 50 50"
                      enableBackground="new 0 0 50 50;"
                    >
                      <path
                        fill="#000"
                        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                      >
                        <animateTransform
                          attributeType="xml"
                          attributeName="transform"
                          type="rotate"
                          from="0 25 25"
                          to="360 25 25"
                          dur="0.6s"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  </div>
                ) : null}
                <img
                  src={image !== null ? REACT_APP_BACKEND_URL + image : Photo}
                  alt="avatar"
                  className="avatar"
                />
                <span className="popover">Changez votre profile photo</span>
              </label>
              <div className="sub-form">
                <input
                  type="file"
                  name="file"
                  id="file-input"
                  onChange={(e) => uploadAvatar(e.target.files[0])}
                />
                <CustomInput
                  margin="0 0.5em"
                  label="Identifiant client"
                  name="IDClient"
                  id="IDClient"
                  type="text"
                />
                <CustomInput
                  margin="0 0.5em"
                  label="Email"
                  name="email"
                  id="email"
                  type="text"
                />
                <CustomInput
                  margin="0 0.5em"
                  label="Password"
                  name="pwd"
                  id="pwd"
                  type="password"
                />
              </div>
              <h3>Informations Personnelles</h3>
              <div className="sub-form">
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Prénom"
                    name="fname"
                    id="fname"
                    type="text"
                    disabled
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Nom"
                    name="lname"
                    id="lname"
                    type="text"
                    disabled
                  />
                </div>
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Date de Naissance"
                    name="birthdate"
                    id="birthdate"
                    type="date"
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Numéro de téléphone"
                    name="phone"
                    id="phone"
                    type="text"
                  />
                </div>
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Nationalité"
                    name="nationality"
                    id="nationality"
                    type="text"
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="CIN"
                    name="cin"
                    id="cin"
                    type="text"
                  />
                </div>
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Adresse"
                    name="address"
                    id="address"
                    type="text"
                  />
                  <div className="city-select-wrp">
                    <label>Ville</label>
                    <select
                      className="select width-unset"
                      name="city"
                      id="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      {CityList.map((city, index) => {
                        return (
                          <option key={`city_${index}`} value={city}>
                            {city}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Code Postal"
                    name="postcode"
                    id="postcode"
                    type="text"
                  />
                  <div className="city-select-wrp">
                    <label>Profession</label>
                    <select
                      className="select width-unset"
                      name="profession"
                      id="profession"
                      value={profession}
                      onChange={(e) => setProfession(e.target.value)}
                    >
                      {clientCategories.map((item, index) => {
                        return (
                          <option
                            key={`profession_${index}`}
                            value={item.clientCategory_name}
                          >
                            {item.clientCategory_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="btn-wrp">
                  <Button
                    handleClick={handleSubmit}
                    title={isSubmitting ? "Enregister..." : "Enregister"}
                    type="submit"
                    radius="7px"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {popup ? (
          <SuccessPopUp
            closePopup={() => {
              setPopup(0);
              history.push("/clients");
            }}
            message="Client modifier"
            target="Modification compte Client"
          />
        ) : null}
      </Container>
    </Layout>
  );
};

export default UpdateClient;

const Container = styled.div`
  background: #fff;
  width: 100%;
  max-width: 650px;
  margin: auto;
  margin-top: 2em;
  padding: 0em 0em 1em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-column-gap: 2em;
  }
  .sub-form {
    padding: 0 2em;
    width: 100%;
  }
  .left-side {
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25vw;
    min-width: 350px;
    max-width: 420px;
    margin-bottom: 1em;
    height: fit-content;
    margin-right: 1.5em;
  }
  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    object-fit: cover;
  }
  .right-side {
    max-width: 750px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 1em;
  }

  input[type="email"],
  input[type="password"] {
    width: 100%;
  }
  input,
  textarea {
    width: 100% !important;
  }
  h3 {
    width: 100%;
    color: #868e96 !important;
    font-weight: 500;
    font-size: 16px;
    padding: 3em 2em 0.5em 2em;
    border-bottom: 1px solid #e1e5eb !important;
  }
  .profil {
    padding: 1.5em 2em 0.5em 2em;
  }
  form {
    width: 100%;
  }
  #file-input {
    display: none;
  }
  .file-label {
    margin-top: 1em;
  }
  .btn {
    position: relative;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border: 1px solid #f84b60;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    color: #f84b60;
    cursor: pointer;
    svg {
      margin-left: 5px;
    }
  }
  p {
    padding: 0.75em 1em;
    text-align: justify;
    color: #5a6169;
  }
  label {
    position: relative;
  }
  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #f84b60;
    }
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -65px;
    top: -5px;
    display: none;
    &:before {
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .active {
    display: block;
  }
  .hidden {
    width: 0;
    height: 0;
    position: absolute;
  }
  .error {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .avatar:hover + span {
    display: block;
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -110px;
    top: 35px;
    display: none;
    width: 100px;
    line-height: 1.5em;
    text-align: justify;
    &:before {
      top: 18px;
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  //new select
  .select {
    min-width: 260px;
    width: 100%;
    padding: 10px;
    max-width: 470px;
    margin: 0.25em 0;
  }
  .city-select-wrp {
    display: flex;
    flex-direction: column;
    label {
      font-size: 14px;
      font-weight: 600;
      color: #4d4d4d;
      margin-bottom: 0.35em;
      margin-top: 1.4em;
    }
  }
  select {
    font-size: 14px;
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    outline: none;
    color: #000;
    cursor: pointer;
  }
  .width-unset {
    max-width: unset;
  }

  @media only screen and (max-width: 576px) {
    .sub-form {
      padding: 0 1em;
    }
  }
`;
