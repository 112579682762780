import React, { createContext, useState, useLayoutEffect } from "react";
import jwt from "jsonwebtoken";

const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({});
  const token = localStorage.getItem("admin");
  const { REACT_APP_BACKEND_URL } = process.env;

  const themeContext = {
    theme,
    setTheme,
  };

  useLayoutEffect(() => {
    const localTheme = JSON.parse(localStorage.getItem("theme"));
    setTheme(localTheme);
  }, []);

  return (
    <ThemeContext.Provider value={themeContext}>
      {children}
    </ThemeContext.Provider>
  );
};

export const ThemeConsumer = ThemeContext.Consumer;

export default ThemeContext;
