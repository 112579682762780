import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import Button from "./elements/Button";
import { ReactComponent as CloseIcon } from "../assets/close-icon.svg";

const AddCsv = ({
  uploadFile,
  apiData,
  setpopup,
  popup,
  theme,
  title,
  setState,
  state,
  color,
  loading,
  setLoading,
}) => {
  const [filename, setFileName] = useState("");
  const [filsize, setFileSize] = useState(0);
  const [filsource, setFileSource] = useState("");
  const [apiTags, setApiTags] = useState([]);
  const [inputType, setInputType] = useState("");
  const [error, setError] = useState(false);
  const [columns, setColumns] = useState([]);
  const [csvfile, setCsvFile] = useState(null);
  const [selected, setSelected] = useState(false);
  const [initalcsvfile, setInitalCsvFile] = useState(null);
  const [data, setData] = useState([]);
  const [errPi, setErrPi] = useState(false);
  const [success, setSuccess] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);
  const [affiche, setAffiche] = useState(false);

  const [fileInfo, setFileInfo] = useState({
    name: "",
    size: 0,
    type: ".csv",
    file: [{ Object }],
    source: theme.id,
  });

  const compareTags = (tags) => {
    let temp = null;
    if (tags.length === apiTags[0]?.tags.length) {
      for (let index = 0; index < tags.length; index++) {
        if (!apiTags[0].tags.includes(tags[index].name)) {
          setError(true);
          temp = true;
          console.log("value not found : ", tags[index].name);
          return;
        }
      }
      if (temp === null) {
        setError(false);
      }
    } else {
      setError(false);
    }
  };

  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    setFileInfo({ ...fileInfo, file: list });
    setInitalCsvFile(dataString);
    setCsvFile(list);
    setColumns(columns);
    compareTags(columns);
    setData(list);
  };

  const readCSV = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      // /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (csvfile && initalcsvfile) {
      console.log("file already selected");
      console.log(initalcsvfile);
      processData(initalcsvfile);
    }
  }, [filsource]);
  const pushLeads = async (data, source) => {
    setIsWaiting(true);
      await Promise.all(data.map(lead => fetch(process.env.REACT_APP_BACKEND_URL + "/api/pushleads", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source,
          tags: {
            ...lead
          }
        })
      }).then(resp => resp.json())
      )
     ).then((respJson) =>{
       respJson.map(resp => {
         if(resp.status != 200){
           setError(true)
           return;
         }
       })
       setIsWaiting(false)
       setAffiche(true)
     })
     
   
  }

  return (
    <Container color={color}>
      <div className="content-custom-popup">
        <div className="top-custom-popup">
          <h4>{title}</h4>
          <CloseIcon
            onClick={() => {
              setState(!state);
            }}
          />
        </div>
        <div className="custom-popup-body">
          <div className="popup-row">
            <h4 className="h4-header">Nom du fichier</h4>
            <div>
              <input
                type="text"
                name="nom"
                id="nom"
                className="input-name"
                placeholder="Veuillez choisir un nom"
                // value={fileInfo.name}
                required
                onChange={(e) => {
                  setFileName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="popup-row">
            <h4 className="h4-header">Source</h4>
            <select
              onChange={(e) => {
                const value = [];
                for (let index = 0; index < apiData.length; index++) {
                  if (apiData[index].source === e.target.value) {
                    value.push(apiData[index]);
                  }
                }
                setFileSource(e.target.value);
                setApiTags(value);
                if (e.target.value === ".") {
                  setSelected(false);
                } else {
                  setSelected(true);
                }
              }}
            >
              <option value=".">Choisir...</option>
              {apiData
                ? apiData.map((item, index) => {
                    return (
                      <option value={item.source} key={index}>
                        {item.source}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          {apiTags.length !== 0 ? (
            <div className="popup-row">
              <h4 className="h4-header">Structure demander</h4>
              <div className="table-nopadding">
                {apiTags[0].tags.map((tag, index) => {
                  return (
                    <span key={index} className="table-span">
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div className="popup-row-no-grid">
            {error ? (
              <p className="p-error">
                <span className="red">*</span>le format du fichier n&apos;est
                pas compatible
              </p>
            ) : null}
          </div>
          <div className="popup-row">
            <h4 className="h4-header">Fichier</h4>
            <div>
              <input
                type="file"
                name="file"
                id="file"
                accept=".csv,.xlsx,.xls"
                className="inputfile"
                disabled={!selected}
                onChange={(e) => {
                  if (e.target.files[0]) {
                    setLoading({ ...loading, value: "Importer" });
                    setInputType(e.target.files[0].name);
                    setFileSize(e.target.files[0].size);
                    readCSV(e);
                  }
                }}
              />

              <label htmlFor="file">Choisissez un fichier</label>
              <span className="input-description">
                {inputType.length !== 0 ? inputType : "..."}
              </span>
            </div>
          </div>
          <div className="footer">
            <Button
              title="Annuler"
              color="#808080"
              padding="0.5em"
              radius="7px"
              margin="0.25em"
              handleClick={(e) => {
                e.preventDefault();
                setpopup(!popup);
              }}
            />
            {errPi && affiche ? <p className="erreur">Une erreur s'est produite, veuillez réessayer</p> : null}
            {success && affiche ? <p className="success">Opération terminée avec succès</p> : null}
            {isWaiting ? <p>veuillez patienter</p>: null}
            {loading.value ? (
              <Button
                title={loading.value}
                color="#808080"
                padding="0.5em"
                radius="7px"
                margin="0.25em"
                disabled={error}
                handleClick={(e) => {
                  e.preventDefault();
                  uploadFile(filename, filsize, filsource, csvfile);
                  pushLeads(data, filsource)
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AddCsv;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  margin: 0;

  .top-custom-popup {
    display: flex;
    padding: 0.25em 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .success{
   color: #28a745!important;
  }
  .erreur{
    color: #dc3545!important;
  }
  .custom-popup-body {
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 2em;
    margin: 0.5em 0em 0.5em 0;
  }
  .content-custom-popup {
    max-width: 800px;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    background: #fff;
    border-radius: 10px;
  }
  .popup-row-fixed {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    padding: 1em 0;
  }
  .popup-row-no-grid {
    display: flex;
    align-items: center;
    .p-error {
      font-size: 14px;
      color: #808080;
    }
    .red {
      color: red;
      margin: 0 0.5em;
    }
  }
  .popup-row {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    margin: 0.75em 0;
    select {
      border-radius: 7px;
      background-color: #f1f1f1;
      color: #808080;
      outline: none;
      border: none;
      max-width: 175px;
      height: 40px;
    }
    .text-area {
      background-color: #f1f1f1;
      padding: 10px;
      border-radius: 7px;
      color: #808080;
      min-height: 100px;
    }
    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .inputfile + label {
      color: white;
      width: fit-content;
      padding: 0.5em 1em 0.45em 1em;
      border-radius: 10px 0 0 10px;
      background-color: ${(props) => props.color};
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
    }
    .input-description {
      font-size: 14px;
      background-color: #f1f1f1;
      padding: 0.5em 1em;
      color: #b5b5b5;
      border-radius: 0 10px 10px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .h4-header {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e2e2e2;
    padding: 0.5em 0;
    .btn {
      outline: none;
      border: none;
      padding: 7px;
      border-radius: 7px;
      color: #fff;
      &:disabled {
        background-color: #b5b5b5;
        cursor: not-allowed;
      }
    }
    .red {
      background-color: #c61a1a;
    }
    .bleu {
      background-color: #2d4185;
    }
  }
  .plus-btn {
    padding: 0.5em 1em;
    background-color: #2d4185;
    color: #fff;
    border-radius: 7px;
    margin-left: -5px;
  }
  .input-name {
    border-radius: 7px;
    padding: 10px;
    background-color: #f1f1f1;
    color: #808080;
    outline: none;
    border: none;
    max-width: 200px;
  }
  .table-nopadding {
    display: flex;
    padding: 0.25em 0;
    height: fit-content;
    width: fit-content;
    flex-wrap: wrap;
    border-radius: 7px;
    //border: 1px solid #b5b5b5;
    color: #808080;
    .table-span {
      padding: 0.25em 0.75em;
      background-color: #f1f1f1;
      margin: 0.25em;
      border-radius: 7px;
      /* border-right: 1px solid #b5b5b5; */
    }
  }
  .table-span:last-child {
    border-right: none;
  }
  @media only screen and (max-width: 768px) {
    .content-custom-popup {
      max-height: 500px;
      width: 90%;
    }
  }
  @media only screen and (max-width: 586px) {
    .popup-row {
      grid-template-columns: 100% !important;
      grid-template-rows: auto auto;
    }
  }
`;
