import React, { useContext } from "react";
import styled from "styled-components";
import ThemeContext from "../../contexts/ThemeContext";

const Button = ({
  title,
  width,
  type,
  margin,
  radius,
  padding,
  font,
  handleClick,
  disabled,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Container
      width={width}
      margin={margin}
      radius={radius}
      padding={padding}
      font={font}
      color={theme?.color}
    >
      <button type={type} onClick={handleClick} disabled={disabled}>
        {title}
      </button>
    </Container>
  );
};

export default Button;

const Container = styled.div`
  width: ${(props) => (props.width === "large" ? "100%" : "auto")};
  font-family: inherit;
  button {
    font-family: inherit;
    font-size: ${(props) => (props.font ? props.font : "15px")};
    font-weight: 500;
    background: ${(props) => props.color || "#2d4185"};
    color: #fff;
    padding: ${(props) => (props.padding ? props.padding : "8px 22px")};
    border-radius: ${(props) => (props.radius ? props.radius : "100px")};
    border: 2px solid ${(props) => props.color || "#2d4185"};
    cursor: pointer;
    transition: all 0.5s;
    margin-top: ${(props) => (props.margin ? props.margin : "2.5em")};
    width: ${(props) => (props.width === "large" ? "100%" : "auto")};
    &:hover {
      background: transparent;
      color: ${(props) => props.color || "#2d4185"};
    }
    &:disabled {
      background-color: #b5b5b5;
      cursor: not-allowed;
      border: none;
    }
  }
`;
