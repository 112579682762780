import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Popup from "../components/Popup";
import SearchBar from "../components/SearchBar";
import Layout from "../layouts/DefaultLayout";
import AdminContext from "../contexts/AdminContext";
import Pagination from "../components/Pagination";
import ThemeContext from "../contexts/ThemeContext";
import DeletePopUp from "../components/PopUps/DeletePopup";
import CustomPopUp from "../components/elements/CustomPopUp";

const Simulations = () => {
  const [simulations, setSimulations] = useState([]);
  const [view, setView] = useState(0);
  const [item, setItem] = useState();
  const [fetching, setFetch] = useState(0);
  const [initialSimulations, setInitialSimulations] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");
  const history = useHistory();
  const { admin } = useContext(AdminContext);
  const { theme } = useContext(ThemeContext);
  const [total, setTotal] = useState(1);
  const [popup, setPopup] = useState(0);
  const [allSimulations, setAllSimulations] = useState([]);
  const [currentPage, setCurrrentPage] = useState(1);
  const postsPerPage = 4;

  const getSimulations = async (page) => {
    setFetch(1);
    const offest = (page - 1) * postsPerPage;
    let query1 = null;
    let query2 = null;
    let query3;

    if (theme?.name?.includes("Autohall")) {
      console.log("parent brand");
      query1 = `${REACT_APP_BACKEND_URL}/simsTotal-parentBrand`;
      query2 = `${REACT_APP_BACKEND_URL}/simulations/${postsPerPage}/${offest}`;
      query3 = `${REACT_APP_BACKEND_URL}/simulations`;
    } else {
      console.log("not parent brand");
      query1 = `${REACT_APP_BACKEND_URL}/simsTotal/${theme.id}`;
      query2 = `${REACT_APP_BACKEND_URL}/simulations/${theme.id}/${postsPerPage}/${offest}`;
      query3 = `${REACT_APP_BACKEND_URL}/simulations/${theme.id}`;
    }

    try {
      const response = await fetch(query1, {
        method: "GET",
      });

      const res = await response.json();
      setTotal(res.total);

      const response2 = await fetch(query2, {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res2 = await response2.json();

      const response3 = await fetch(query3, {
        method: "GET",
      });
      const res3 = await response3.json();
      setSimulations(res3);
      setInitialSimulations(res2);
      setFetch(0);
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteSimulation = async (id) => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/simulation/" + id,
        {
          method: "DELETE",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (res.message) {
        setPopup(0);
        const arr = initialSimulations.filter(
          (item) => item.simulation_id !== id
        );
        setSimulations(arr);
        setInitialSimulations(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ViewSimulation = (item) => {
    console.log(item);
    setItem(item);
    setView(1);
  };

  const getDate = (date) => {
    const d = new Date(date);
    return (
      d.toLocaleDateString("fr-Fr") + "-" + d.getHours() + ":" + d.getMinutes()
    );
  };

  useEffect(() => {
    if (
      admin?.role?.includes("Clients") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/complaints");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/");
    }
  }, [admin]);

  useEffect(() => {
    if (theme.id) {
      getSimulations(1);
    }
  }, [theme]);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = simulations.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrrentPage(pageNumber);

  return (
    <Layout>
      <Container brand={theme?.name}>
        {view ? (
          <CustomPopUp>
            <Popup title="Simulation" setView={setView} item={item} />
          </CustomPopUp>
        ) : null}
        <SearchBar
          title="Liste des Simulations"
          state={simulations}
          setState={setInitialSimulations}
          data={simulations}
          type="simulations"
          link="/edit-credit"
          btnText={admin?.role?.includes("Super") ? "Paramètre" : null}
          component="simulations"
          setTotal={setTotal}
          setData={setSimulations}
          isNull={(page) => getSimulations(page)}
        />
        <div className="table">
          <div className="grid">
            <div className="grid-row">
              <h6>Prénom Nom</h6>
              <h6>Email</h6>
              <h6>Téléphone</h6>
              <h6>Profession</h6>
              <h6>Coût global</h6>
              <h6>Modèle</h6>
              <h6>Date</h6>
              {theme?.name?.toString().toLowerCase() === "autohall" ? (
                <h6>Source</h6>
              ) : null}
              <h6 />
            </div>
            {fetching !== 0 ? (
              <div className="fetching">
                <svg
                  version="1.1"
                  id="loader-1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 50 50"
                  enableBackground="new 0 0 50 50;"
                >
                  <path
                    fill="#000"
                    d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                  >
                    <animateTransform
                      attributeType="xml"
                      attributeName="transform"
                      type="rotate"
                      from="0 25 25"
                      to="360 25 25"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </div>
            ) : simulations.length !== 0 ? (
              currentPosts.map((item, index) => (
                <div key={index} className="grid-row">
                  <h5 className="name">{item.simulation_name}</h5>
                  <h5>
                    {item.simulation_email ? item.simulation_email : "---"}
                  </h5>
                  <h5>
                    {item.simulation_phone ? item.simulation_phone : "---"}{" "}
                  </h5>
                  <h5>
                    {item.simulation_profession
                      ? item.simulation_profession
                      : "---"}
                  </h5>
                  <h5>{item.simulation_global_cost} DH</h5>
                  <h5>{item.simulation_model} </h5>
                  <h5>{getDate(item.created_at)}</h5>
                  {theme?.name?.toString().toLowerCase() === "autohall" ? (
                    <h5>
                      {typeof item.brand_img === "undefined" ? (
                        item.brand_name
                      ) : (
                        <img
                          className="brand-logo"
                          src={REACT_APP_BACKEND_URL + item.brand_img}
                          alt="logo"
                        />
                      )}
                    </h5>
                  ) : null}

                  <h5 className="delete">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="#f84b60"
                      onClick={() => setPopup(item.simulation_id)}
                    >
                      <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      className="view"
                      fill="#187bcd"
                      onClick={() => ViewSimulation(item)}
                    >
                      <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z" />
                    </svg>
                  </h5>
                </div>
              ))
            ) : (
              <div className="not-found">
                <p>
                  <span className="red">*</span>pas de résultat
                </p>
              </div>
            )}
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            paginate={paginate}
            setCurrrentPage={setCurrrentPage}
            totalPosts={total}
            currentPage={currentPage}
          />
          {popup ? (
            <DeletePopUp
              deleteItem={() => DeleteSimulation(popup)}
              cancleOperation={() => setPopup(0)}
              target="Supprimer simulation"
              message="êtes-vous sûr de vouloir supprimer cette simulation"
            />
          ) : null}
        </div>
      </Container>
    </Layout>
  );
};

export default Simulations;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    color: #726e6e;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    font-weight: 600;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: flex;
    flex-direction: column;
    min-width: 1200px;
    .grid-row {
      display: grid;
      grid-template-columns: ${(props) =>
        props.brand === "Autohall"
          ? "1fr 2fr 0.7fr 0.7fr 0.7fr 1.5fr 0.8fr 0.8fr 75px"
          : "1fr 2fr 0.7fr 0.7fr 0.7fr 1.5fr 0.8fr 0.8fr 75px"};
    }
  }
  .brand-logo {
    width: 100%;
    max-width: 60px;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
  .not-found,
  .fetching {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #aaa;
    }
    .red {
      color: red;
      margin: 0 1em;
    }
  }
`;
