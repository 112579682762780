import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import AddAdmin from "./pages/AddAdmin";
import AddClient from "./pages/AddClient";
import AddScenario from "./pages/AddScenario";
import Admins from "./pages/Admins";
import Brand from "./pages/Brand";
import Clients from "./pages/Clients";
import Complaints from "./pages/Complaints";
import EditRvs from "./pages/EditRvs";
import EditClient from "./pages/EditClient";
import EditAdmin from "./pages/EditAdmin";
import Rvs from "./pages/Rvs";
import Simulations from "./pages/Simulations";
import EditSimulation from "./pages/EditSimulation";
import EditCredit from "./pages/EditCredit";
import LeadsHome from "./pages/LeadsHome";
import ListeFichier from "./pages/FileLists";
import SitesConfig from "./pages/SitesConfig";
import EditSites from "./pages/EditSites";
import AddSite from "./pages/AddSite";
import ListLeads from "./pages/ListLeads";
import ViewApi from "./pages/ViewApi";
import ListLeadsCsv from "./pages/ListLeadsCsv";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AdminProvider } from "./contexts/AdminContext";

const App = () => {
  return (
    <ThemeProvider>
      <AdminProvider>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path={["/", "/dashboard"]} component={LeadsHome} />
            <Route exact path="/clients" component={Clients} />
            <Route exact path="/add-admin" component={AddAdmin} />
            <Route exact path="/add-client" component={AddClient} />
            <Route exact path="/add-scenario" component={AddScenario} />
            <Route exact path="/admins" component={Admins} />
            <Route exact path="/brand" component={Brand} />
            <Route exact path="/complaints" component={Complaints} />
            <Route exact path="/edit-credit" component={EditCredit} />
            <Route exact path="/edit-rvs" component={EditRvs} />
            <Route exact path="/edit-simulation" component={EditSimulation} />
            <Route exact path="/rvs" component={Rvs} />
            <Route exact path="/simulations" component={Simulations} />
            <Route exact path="/edit-client" component={EditClient} />
            <Route exact path="/edit-admin" component={EditAdmin} />
            <Route exact path="/leads-list-fichier" component={ListeFichier} />
            <Route exact path="/leads-sites" component={SitesConfig} />
            <Route exact path="/add-site" component={AddSite} />
            <Route exact path="/edit-sites" component={EditSites} />
            <Route exact path="/list-leads" render={() => <ListLeads />} />
            <Route exact path="/list-leads-csv" component={ListLeadsCsv} />
            <Route exact path="/view-sites" component={ViewApi} />
          </Switch>
        </Router>
      </AdminProvider>
    </ThemeProvider>
  );
};

export default App;
