import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import CustomInput from "./elements/CustomInput";
import Button from "./elements/Button";

const Parametres = () => {
  const [isAvailble, setIsAvailble] = useState(0);
  const [credits, setCredits] = useState([]);
  const [eligibleVehicles, setEligibleVehicles] = useState([]);
  const [eligibleClients, setEligibleClients] = useState([]);
  const [params, setParams] = useState({});
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");

  const getCredits = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/credits", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setCredits(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getEligibility = async (credit) => {
    let id = "";

    credits.map((item) => {
      if (item.credit_type == credit) {
        id = item.credit_id;
      }
    });
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/eligibility/" + id,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setEligibleVehicles(res.vehicles);
      setEligibleClients(res.clients);
    } catch (err) {
      console.log(err);
    }
  };

  const getParams = async (credit, vehicle, client) => {
    if (credit === "") {
      credit = null;
    }
    if (vehicle === "") {
      vehicle = null;
    }
    if (client === "") {
      client = null;
    }

    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + `/param/${credit}/${vehicle}/${client}`,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (res) {
        setParams(res);
        setIsAvailble(1);
      } else {
        setParams({
          params_creditType: credit,
          params_vehicleType: vehicle,
          params_clientCategory: client,
        });
        setIsAvailble(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const add = async (data) => {
    if (isAvailble) {
      try {
        const response = await fetch(
          REACT_APP_BACKEND_URL + "/params/" + params.params_id,
          {
            method: "PUT",
            headers: {
              "x-access-token": token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...data,
            }),
          }
        );

        const res = await response.json();
        if (res.message) {
          alert("Les paramètres ont été ajoutés avec succès!");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const response = await fetch(REACT_APP_BACKEND_URL + "/params", {
          method: "POST",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
          }),
        });

        const res = await response.json();
        if (res.message) {
          alert("Les paramètres ont été ajoutés avec succès!");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getCredits();
  }, []);

  return (
    <Container>
      <h4>Paramètre de calcul</h4>
      <Formik
        enableReinitialize
        initialValues={{
          tva: params?.params_tva !== undefined ? params?.params_tva : "",
          tvaLoc:
            params?.params_tvaLoc !== undefined ? params?.params_tvaLoc : "",
          tvaIV: params?.params_tvaIV !== undefined ? params?.params_tvaIV : "",
          insurance:
            params?.params_insurance !== undefined
              ? params?.params_insurance
              : "",
          otherInsurance:
            params?.params_otherInsurance !== undefined
              ? params?.params_otherInsurance
              : "",
          interimInterest:
            params?.params_interimInterest !== undefined
              ? params?.params_interimInterest
              : "",
          deferredDuration:
            params?.params_deferredDuration !== undefined
              ? params?.params_deferredDuration
              : "",
          durationMax:
            params?.params_durationMax !== undefined
              ? params?.params_durationMax
              : "",
          credit: params?.params_creditType || null,
          vehicle: params?.params_vehicleType || null,
          client: params?.params_clientCategory || null,
        }}
        validationSchema={Yup.object({
          tva: Yup.number().typeError("Numerique"),
          tvaLoc: Yup.number().typeError("Numerique"),
          tvaIV: Yup.number().typeError("Numerique"),
          insurance: Yup.number().typeError("Numerique"),
          otherInsurance: Yup.number().typeError("Numerique"),
          interimInterest: Yup.number().typeError("Numerique"),
          deferredDuration: Yup.number().typeError("Numerique"),
          durationMax: Yup.number().typeError("Numerique"),
          credit: Yup.string().required("Champs Obligatoire"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await add(data);
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          isSubmitting,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <div className="grid">
              <div className="grid-card">
                <div className="row select-row">
                  <span>Type de crédit: </span>
                  <select
                    name="credit"
                    id="credit"
                    onChange={(e) => {
                      getEligibility(e.target.value);
                      handleChange(e);
                      getParams(
                        e.target.value,
                        values.vehicle || null,
                        values.client || null
                      );
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="">Choisir...</option>
                    {credits.map((item, index) => (
                      <option
                        value={item.credit_type}
                        key={index}
                        id={item.credit_id}
                      >
                        {item.credit_type}
                      </option>
                    ))}
                  </select>
                </div>
                <p>{errors.credit && touched.credit ? errors.credit : null}</p>
                {values.credit ? (
                  <div className="row select-row">
                    <span>Type de véhicule: </span>
                    <select
                      name="vehicle"
                      id="vehicle"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        getParams(
                          values.credit,
                          e.target.value,
                          values.client || null
                        );
                      }}
                    >
                      <option value="">Choisir...</option>
                      {eligibleVehicles.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                {values.credit && values.vehicle ? (
                  <div className="row select-row">
                    <span>Catégorie de client: </span>
                    <select
                      name="client"
                      id="client"
                      onChange={(e) => {
                        handleChange(e);
                        getParams(
                          values.credit,
                          values.vehicle,
                          e.target.value
                        );
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">Choisir...</option>
                      {eligibleClients.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                <div className="btn1">
                  <Button
                    handleClick={handleSubmit}
                    title={isSubmitting ? "Modifier..." : "Modifier"}
                    margin="0"
                    radius="7px"
                    padding="6px 22px"
                  />
                </div>
              </div>

              <div className="grid-card">
                <div className="row input-row">
                  <span>TVA: </span>
                  <CustomInput name="tva" id="tva" type="text" />
                </div>
                <div className="row input-row">
                  <span>TVA sur la location: </span>
                  <CustomInput name="tvaLoc" id="tvaLoc" type="text" />
                </div>
                <div className="row input-row">
                  <span>TVA sur interet de la vac: </span>
                  <CustomInput name="tvaIV" id="tvaIV" type="text" />
                </div>
                <div className="row input-row">
                  <span>Assurance: </span>
                  <CustomInput name="insurance" id="insurance" type="text" />
                </div>
                <div className="row input-row">
                  <span>Autres Assurance: </span>
                  <CustomInput
                    name="otherInsurance"
                    id="otherInsurance"
                    type="text"
                  />
                </div>
                <div className="row input-row">
                  <span>Internet intercalaire: </span>
                  <CustomInput
                    name="interimInterest"
                    id="interimInterest"
                    type="text"
                  />
                </div>
                <div className="row input-row">
                  <span>Durée diffère:</span>
                  <CustomInput
                    name="deferredDuration"
                    id="deferredDuration"
                    type="text"
                  />
                </div>
                <div className="row input-row">
                  <span>Durée Max (Mois):</span>
                  <CustomInput
                    name="durationMax"
                    id="durationMax"
                    type="text"
                  />
                </div>
                <div className="btn2">
                  <Button
                    handleClick={handleSubmit}
                    title={isSubmitting ? "Modifier..." : "Modifier"}
                    margin="0"
                    radius="7px"
                    padding="6px 22px"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Parametres;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  padding: 1.5em 2em;
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 4em;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 1em;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding: 0.5em 0;
  }
  select {
    outline: none;
    padding: 10px 5px;
    font-size: 13px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    min-width: ${(props) => (props.small ? "170px" : "260px")};
    margin-bottom: 0.35em;
    max-width: ${(props) => (props.small ? "170px" : "unset")};
  }
  span {
    font-size: 14px;
    font-weight: 600;
    padding: 0.25em 0;
  }
  button {
    margin-top: 3em;
    float: right;
  }
  input {
    padding: 6px 10px;
    font-size: 13px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    width: 70px;
    min-width: unset;
  }
  label {
    display: none;
  }
  .input-row {
    padding: 0.2em 0;
    div {
      width: min-content;
    }
  }
  .btn2 {
    display: none;
  }
  p {
    font-size: 12px;
    color: red;
    font-weight: 600;
  }
  @media only screen and (max-width: 1250px) {
    .grid {
      display: block;
    }
    .grid-card {
      margin-bottom: 3em;
    }
    button {
      float: unset;
    }
    .btn1 {
      display: none !important;
    }
    .btn2 {
      display: block !important;
    }
  }
`;
