import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import LeadsHeader from "../components/leads/LeadsHeader";
import Button from "../components/elements/Button";
import AdminContext from "../contexts/AdminContext";
import ThemeContext from "../contexts/ThemeContext";
import Pagination from "../components/Pagination";
import AddCsv from "../components/AddCsv";

const ListFichier = () => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  const history = useHistory();
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrrentPage] = useState(1);
  const [fichierlist, setFichierlist] = useState([]);
  const [initfichierlist, setInitFichierlist] = useState([]);
  const [popup, setpopup] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState({ status: false, value: "Importer" });
  const { admin } = useContext(AdminContext);
  const postsPerPage = 12;

  const getDataSource = async () => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/leads/get-site-structure",
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      setApiData(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getFilesSource = async (page) => {
    const offset = (page - 1) * postsPerPage;
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/leads/get-all-csvs/${postsPerPage}/${offset}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      setFichierlist(res);
      setInitFichierlist(res);
    } catch (err) {
      console.log(err);
    }
  };

  const uploadFile = async (name, size, source, data) => {
    setLoading({ ...loading, status: true, value: "loading..." });
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/leads/uploadcsv", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name,
          size,
          source,
          data,
        }),
      });
      const res = await response.json();
      if (res.message === "success") {
        setLoading({ ...loading, status: false, value: res.message });
        
      }
    } catch (err) {
      console.log(err.message);
      setLoading({ ...loading, status: false, value: "erreur c'est produits" });
    }
  };

  const countDocuments = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/leads/count-all-csvs`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      setTotal(res);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getDataSource();
    getFilesSource(theme.id);
    countDocuments();
  }, []);

  useEffect(() => {
    if (admin?.role?.includes("Clients") && !admin?.role?.includes("Leads")) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/complaints");
    }
  }, [admin]);

  return (
    <Layout>
      <Container>
        <LeadsHeader
          title="Liste des fichiers"
          btnText="Ajouter"
          state={initfichierlist}
          setState={setFichierlist}
          popupstate={popup}
          setpopupstate={setpopup}
          parent="fileslist"
        />
        <div className="table">
          <div className="grid">
            <div className="row row-top">
              <h6>Nom</h6>
              <h6>Source</h6>
              <h6>Ajouter le</h6>
              <h6>Type</h6>
              <h6>Taille</h6>
            </div>
            {fichierlist.length !== 0 ? (
              fichierlist.map((item, index) => {
                return (
                  <div className="row row-border" key={index}>
                    <h5 className="name">{item?.name ? item?.name : "..."}</h5>
                    <h5 className="data">
                      {item?.source ? item?.source : "..."}
                    </h5>
                    <h5 className="data">{item?.date ? item?.date : "..."}</h5>
                    <h5 className="data">{item?.type ? item?.type : "..."}</h5>
                    <h5 className="data">{item?.size ? item?.size : "..."}</h5>
                  </div>
                );
              })
            ) : (
              <p className="no-result-msg">
                <span className="red">*</span>pas de resltat trouver
              </p>
            )}
          </div>
        </div>
        {popup ? (
          <AddCsv
            title="Importer un fichier"
            state={popup}
            setState={setpopup}
            color={theme?.color}
            theme={theme}
            setpopup={setpopup}
            popup={popup}
            apiData={apiData}
            uploadFile={uploadFile}
            setLoading={setLoading}
            loading={loading}
          />
        ) : null}
        <Pagination
          // source={bySource}
          postsPerPage={postsPerPage}
          paginate={getFilesSource}
          setCurrrentPage={setCurrrentPage}
          totalPosts={total}
          currentPage={currentPage}
        />
      </Container>
    </Layout>
  );
};
export default ListFichier;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em;

  .table {
    overflow-x: auto;
    .row {
      min-width: 700px;
    }
  }
  .no-result-msg {
    color: #808080;
    font-size: 13px;
    margin: 0 auto 0.25em 1em;
    padding: 0.5em;
    .red {
      color: red;
      margin: 0 0.25em;
    }
  }
  .row {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
  }
  .row-border {
    border-bottom: 1px solid #dfe0eb;
  }
  .row-top {
    border-bottom: 2px solid #dfe0eb;
    h6 {
      color: #676767;
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
  }
  .name {
    color: #252733;
    font-weight: 600 !important;
  }
  .data {
    color: #9fa2b4;
  }
  .no-border-bnt {
    background-color: transparent;
    padding: 5px 8px;
    font-size: 12px;
    color: #999;
    margin-top: 0.25em;
    text-align: left;
    font-weight: 500;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      fill: #f84b60;
      cursor: pointer;
      margin: 10px;
    }
  }
  svg.view {
    fill: green !important;
  }
`;
