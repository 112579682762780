import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import DashStatsCart from "../components/leads/DashStatsCart";
import DashSitesCart from "../components/leads/DashSitesCart";
import DashGraph from "../components/leads/DashGraph";
import AdminContext from "../contexts/AdminContext";
import datescollection from "../utils/Dates";

const LeadsHome = () => {
  const [graphdata, setGraphdata] = useState({ data: null, dates: null });
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(0);
  const [sites, setSites] = useState([]);
  const [time, setTime] = useState(0);
  const { REACT_APP_BACKEND_URL } = process.env;
  const { admin } = useContext(AdminContext);
  const history = useHistory();

  const getLeads = async () => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/leads/get-leads-date",
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      setLeads(res);

      const resp = await fetch(
        REACT_APP_BACKEND_URL + "/leads/get-leads-source",
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      resp
        .json()
        .then(function (data) {
          setSites(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (admin?.role?.includes("Leads")) {
      history.push("/leads-list-fichier");
    }
  }, []);

  const filterGraphData = (d, t, year) => {
    const dataArr = [];
    let arr = [];
    let arr2 = [];
    let arr3 = [];
    let arr4 = [];
    let Obj = {};
    if (!year) {
      for (let i = 0; i < d.series.length; i++) {
        for (let j = 0; j <= t; j++) {
          if (!d.series[i].data[1].includes(j)) {
            arr.push(j);
            arr2.push(0);
          }
        }
        arr3 = arr.concat(d.series[i].data[1]);
        arr4 = arr2.concat(d.series[i].data[0]);
        arr = [];
        arr2 = [];
        Obj = sortArray(arr3, arr4, d.series[i].name);
        dataArr.push(Obj);
      }
    } else {
      for (let i = 0; i < d.series.length; i++) {
        Obj = sortArray(
          d.series[i].data[1],
          d.series[i].data[0],
          d.series[i].name
        );
        dataArr.push(Obj);
      }
    }
    return dataArr;
  };

  const sortArray = (array1, array2, n) => {
    const len = array1.length;
    for (let i = len - 1; i >= 0; i--) {
      for (let j = 1; j <= i; j++) {
        if (array1[j - 1] > array1[j]) {
          const temp = array1[j - 1];
          const temp2 = array2[j - 1];
          array1[j - 1] = array1[j];
          array2[j - 1] = array2[j];
          array1[j] = temp;
          array2[j] = temp2;
        }
      }
    }

    return { name: n, data: array2 };
  };

  const getGraphData = async (date) => {
    let resultData = null;
    setLoading(1);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/leads/get-graphData/${date}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const result = await response.json();

      switch (date) {
        case 0:
          resultData = filterGraphData(result, 23);
          setGraphdata({
            ...graphdata,
            dates: datescollection[0].content,
            data: resultData,
          });
          break;
        case 1:
          resultData = filterGraphData(result, 53);
          setGraphdata({
            ...graphdata,
            dates: datescollection[1].content,
            data: resultData,
          });
          break;
        case 2:
          resultData = filterGraphData(result, 12);
          setGraphdata({
            ...graphdata,
            dates: datescollection[2].content,
            data: resultData,
          });
          break;
        case 3:
          resultData = filterGraphData(result, 1, "year");
          setGraphdata({
            ...graphdata,
            dates: datescollection[3].content,
            data: resultData,
          });
          // console.log(result);

          break;
        default:
          break;
      }
      setLoading(0);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getLeads();
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getGraphData(time);
    }
    return () => {
      mounted = false;
    };
  }, [time]);

  useEffect(() => {
    if (admin?.role?.includes("Clients") && !admin?.role?.includes("Leads")) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/complaints");
    }
  }, [admin]);

  return (
    <Layout>
      <Container>
        <div className="row row-1">
          <DashStatsCart
            color="#0381FF"
            title="Aujourd'hui"
            newNumber={leads.length !== 0 ? leads[0].day : "..."}
            // oldNumber={
            //   leads.length !== 0 ? `Hier : ${leads[0].dayBefore} p` : "..."
            // }
          />
          <DashStatsCart
            color="#E7961E"
            title="Semaine"
            newNumber={leads.length !== 0 ? leads[1].week : "..."}
            // oldNumber={
            //   leads.length !== 0
            //     ? `Semaine dernière : ${leads[1].weekBefore} p`
            //     : "..."
            // }
          />
          <DashStatsCart
            color="#27A343"
            title="Mois"
            newNumber={leads.length !== 0 ? leads[2].month : "..."}
            // oldNumber={
            //   leads.length !== 0
            //     ? `Mois dernière : ${leads[2].monthBefore} p`
            //     : "..."
            // }
          />
          <DashStatsCart
            color="#D53243"
            title="Année"
            newNumber={leads.length !== 0 ? leads[3].year : "..."}
            // oldNumber={
            //   leads.length !== 0
            //     ? `Année dernière : ${leads[3].yearBefore} p`
            //     : "..."
            // }
          />
        </div>
        <div className="row row-2">
          <DashSitesCart
            data={sites.length !== 0 ? sites : null}
            state={time}
            setState={setTime}
          />
          <DashGraph
            state={loading}
            totals={graphdata?.data}
            dates={graphdata?.dates}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default LeadsHome;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 3em 1em;
  display: grid;
  grid-template-rows: auto auto;

  .row-1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .row-2 {
    display: grid;
    margin-top: 1em;
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 1300px) {
    .row-1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
      grid-template-rows: auto auto;
    }
  }
  @media only screen and (max-width: 1100px) {
    .row-2 {
      display: grid;
      grid-template-columns: 100% !important;
      grid-template-rows: auto auto;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 1em 0.5em;
    .row-1 {
      display: grid;
      grid-template-columns: 100% !important;
      grid-template-rows: auto auto auto auto;
    }
  }
`;
