import React, { Fragment, useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { convertArrayToCSV } from "convert-array-to-csv";
import download from "downloadjs";
import styled from "styled-components";
import Button from "../components/elements/Button";
import Layout from "../layouts/DefaultLayout";
import CustomInput from "../components/elements/CustomInput";
import ThemeContext from "../contexts/ThemeContext";
import AdminContext from "../contexts/AdminContext";
import Pagination from "../components/Pagination";

const AddScenario = () => {
  const { theme } = useContext(ThemeContext);
  const [credits, setCredits] = useState([]);
  const [itemId, setItemId] = useState(-1);
  const [isUpdate, setIsUpdate] = useState(0);
  const [eligibleVehicles, setEligibleVehicles] = useState([]);
  const [eligibleClients, setEligibleClients] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [pageData, setPageData] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");
  const { admin } = useContext(AdminContext);
  const history = useHistory();
  //pagination
  const [currentPage, setCurrrentPage] = useState(1);
  const [total, setTotal] = useState(1);
  const postsPerPage = 12;

  const paginateData = (page) => {
    const offest = (page - 1) * postsPerPage;
    if (page === 1) {
      setPageData(scenarios.slice(0, 12));
      console.log(1);
    } else if (page > 1) {
      console.log(2);
      setPageData(scenarios.slice(offest, offest + postsPerPage));
    }
  };

  const getCredits = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/credits", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setCredits(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getEligibility = async (id) => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/eligibility/" + id,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setEligibleVehicles(res.vehicles);
      setEligibleClients(res.clients);
    } catch (err) {
      console.log(err);
    }
  };

  const getScenarios = async () => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/scenarios/" + admin.brand,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setScenarios(res);
      console.log(res);
      setPageData(res.slice(0, 12));
      setTotal(res.length);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteScenario = async (id) => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/scenario/" + id, {
        method: "DELETE",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      if (res.message) {
        const arr = scenarios.filter((item) => item.scenario_id !== id);
        setScenarios(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const add = async (data) => {
    let creditName = "";

    credits.map((item) => {
      if (item.credit_id == data.credit) {
        creditName = item.credit_type;
      }
    });

    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/scenario", {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          credit: creditName,
          brand: admin.brand,
        }),
      });

      const res = await response.json();
      if (res) {
        const arr = scenarios;

        arr.push({
          scenario_apport: data.apport,
          scenario_duration: data.duration,
          scenario_folderFees: data.folderFees,
          scenario_intrestRate: data.intrestRate,
          scenario_residualValue: data.residualValue,
          scenario_clientCategory: data.client,
          scenario_vehicleType: data.vehicle,
          scenario_creditType: creditName,
          brand_id: admin.brand,
        });
        setScenarios(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const exportCsv = () => {
    const csv = convertArrayToCSV(scenarios);
    download(csv, "data.csv", "application/csv");
  };

  const getCreditId = (name) => {
    let credit = null;
    credits.map((item) => {
      if (item.credit_type === name) {
        credit = item.credit_id;
      }
    });
    return credit;
  };

  const updateScenario = async (data) => {
    let creditName = "";

    credits.map((item) => {
      if (item.credit_id == data.credit) {
        creditName = item.credit_type;
      }
    });

    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/scenario/" + itemId,
        {
          method: "PUT",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
            credit: creditName,
          }),
        }
      );
      const res = await response.json();
      if (res.message) {
        alert("Succès!");
        const arr = scenarios;

        arr.map((item) => {
          if (item.scenario_id === itemId) {
            item.scenario_apport = data.apport;
            item.scenario_duration = data.duration;
            item.scenario_folderFees = data.folderFees;
            item.scenario_intrestRate = data.intrestRate;
            item.scenario_residualValue = data.residualValue;
            item.scenario_clientCategory = data.client;
            item.scenario_vehicleType = data.vehicle;
            item.scenario_creditType = creditName;
          }
        });
        setScenarios(arr);
        setItemId(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      admin?.role?.includes("Clients") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/complaints");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/");
    }
  }, [admin]);

  useEffect(() => {
    getCredits();
  }, []);

  useEffect(() => {
    if (admin.brand) {
      getScenarios();
    }
  }, [admin]);

  return (
    <Layout>
      <Container color={theme?.color}>
        <div className="btns-row">
          <Link to="edit-credit">
            <button>Config du Crédit</button>
          </Link>
          <Link to="/edit-simulation">
            <button>Config des Params</button>
          </Link>
          <Link to="/add-scenario">
            <button className="active">Config des Calculs</button>
          </Link>
        </div>
        <div className="page-container">
          <Formik
            initialValues={{
              apport: 0,
              duration: 0,
              folderFees: 0,
              intrestRate: 0,
              residualValue: 0,
              credit: null,
              vehicle: null,
              client: null,
            }}
            validationSchema={Yup.object({
              apport: Yup.number()
                .typeError("Doit être un nombre")
                .min(0, "Doit être supérieur a 0")
                .max(100, "Doit être inférieur à 100"),
              duration: Yup.number()
                .typeError("Doit être un nombre")
                .min(0, "Doit être supérieur a 0"),
              folderFees: Yup.number()
                .typeError("Doit être un nombre")
                .min(0, "Doit être supérieur a 0")
                .max(100, "Doit être inférieur à 100"),
              intrestRate: Yup.number()
                .typeError("Doit être un nombre")
                .min(0, "Doit être supérieur a 0")
                .max(100, "Doit être inférieur à 100"),
              residualValue: Yup.number()
                .typeError("Doit être un nombre")
                .min(0, "Doit être supérieur a 0")
                .max(100, "Doit être inférieur à 100"),
              credit: Yup.string()
                .typeError("Champs Obligatoire")
                .required("Champs Obligatoire"),
            })}
            onSubmit={async (data, { setSubmitting }) => {
              console.log(isUpdate);
              setSubmitting(true);
              if (isUpdate) {
                await updateScenario(data);
              } else {
                await add(data);
              }
              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              errors,
              values,
              touched,
            }) => (
              <Form className={itemId === -1 ? "form" : "form edit-form"}>
                <div className="row">
                  <h4>Configuration</h4>
                  <Button
                    handleClick={() => exportCsv()}
                    title="Exporter"
                    radius="7px"
                    margin="0px"
                    font="14px"
                    padding="8px 22px"
                    type="button"
                  />
                </div>
                <div className="table">
                  <div className="grid">
                    <h6>APPORT (%)</h6>
                    <h6>Durée (Mois)</h6>
                    <h6>FRAIS DOSSIER (%)</h6>
                    <h6>TAUX d&apos;interet (%)</h6>
                    <h6>val résiduelle</h6>
                    <h6>crédit </h6>
                    <h6>Segment </h6>
                    <h6>CSP</h6>
                    <h6 />
                    {pageData.map((item, index) => (
                      <Fragment key={index}>
                        <h5>{item.scenario_apport}</h5>
                        <h5>{item.scenario_duration}</h5>
                        <h5>{item.scenario_folderFees}</h5>
                        <h5>{item.scenario_intrestRate}</h5>
                        <h5>{item.scenario_residualValue}</h5>
                        <h5>{item.scenario_creditType}</h5>
                        <h5>
                          {item.scenario_vehicleType !==
                          "Voiture particuliére (de tourisme)"
                            ? item.scenario_vehicleType
                            : "Voiture particuliére"}
                        </h5>
                        <h5>{item.scenario_clientCategory}</h5>

                        <h5 className="delete">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="#f84b60"
                            onClick={() => deleteScenario(item.scenario_id)}
                          >
                            <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="#777"
                            style={{ marginLeft: "1em" }}
                            onClick={() => {
                              setItemId(item.scenario_id);
                              setFieldValue("apport", item.scenario_apport);
                              setFieldValue("duration", item.scenario_duration);
                              setFieldValue(
                                "folderFees",
                                item.scenario_folderFees
                              );
                              setFieldValue(
                                "intrestRate",
                                item.scenario_intrestRate
                              );
                              setFieldValue(
                                "residualValue",
                                item.scenario_residualValue
                              );
                              setFieldValue(
                                "client",
                                item.scenario_clientCategory
                              );
                              setFieldValue(
                                "vehicle",
                                item.scenario_vehicleType
                              );

                              const creditId = getCreditId(
                                item.scenario_creditType
                              );
                              setFieldValue("credit", creditId);
                              getEligibility(creditId);

                              window.scrollTo({
                                top: document.body.scrollHeight,
                                behavior: "smooth",
                              });
                            }}
                          >
                            <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z" />
                          </svg>
                        </h5>
                      </Fragment>
                    ))}

                    <CustomInput name="apport" id="apport" type="text" />
                    <CustomInput name="duration" id="duration" type="text" />
                    <CustomInput
                      name="folderFees"
                      id="folderFees"
                      type="text"
                    />
                    <CustomInput
                      name="intrestRate"
                      id="intrestRate"
                      type="text"
                    />
                    <CustomInput
                      name="residualValue"
                      id="residualValue"
                      type="text"
                    />
                    <div>
                      <select
                        name="credit"
                        value={values.credit}
                        onChange={(e) => {
                          getEligibility(e.target.value);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="">Choisir...</option>
                        {credits.map((item, index) => (
                          <option value={item.credit_id} key={index}>
                            {item.credit_type}
                          </option>
                        ))}
                      </select>
                      <p>
                        {errors.credit && touched.credit ? errors.credit : null}
                      </p>
                    </div>
                    <div>
                      <select
                        name="vehicle"
                        value={values.vehicle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Choisir...</option>
                        {eligibleVehicles.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <p>{errors.vehicle}</p>
                    </div>
                    <div>
                      <select
                        name="client"
                        value={values.client}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Choisir...</option>
                        {eligibleClients.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                      <p>{errors.client}</p>
                    </div>
                    {itemId === -1 ? (
                      <Button
                        handleClick={handleSubmit}
                        title="+"
                        type="submit"
                        radius="7px"
                        margin="1em"
                        padding="6px 10px"
                      />
                    ) : (
                      <button
                        className="confirm"
                        type="button"
                        onClick={() => {
                          setIsUpdate(1);
                          handleSubmit();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#187bcd"
                        >
                          <path d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z" />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <Pagination
            totalPosts={total}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            setCurrrentPage={setCurrrentPage}
            paginate={paginateData}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default AddScenario;

const Container = styled.div`
  .page-container {
    padding: 0 1em 1em 1em;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
      rgb(237 239 247 / 47%) 0px 0px 0px;
  }
  .form {
    width: 100%;
    margin-top: 2em;
    padding: 2em 1.25em 0 1.25em;
    border-radius: 20px;
    background: #fff;
  }
  .btns-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    flex-wrap: wrap;
    button {
      font-family: inherit;
      font-size: 15px;
      width: 200px;
      font-weight: 500;
      background: transparent;
      color: ${(props) => props.color || "#2d4185"};
      padding: 8px 24px;
      border-radius: 100px;
      border: 2px solid ${(props) => props.color || "#2d4185"};
      cursor: pointer;
      transition: all 0.5s;
      margin: 0.5em 0.5em;
      &:hover {
        background: ${(props) => props.color || "#2d4185"};
        color: #fff;
      }
    }
    .active {
      background: ${(props) => props.color || "#2d4185"};
      border: 2px solid ${(props) => props.color || "#2d4185"};
      color: #fff;
      &:hover {
        background: transparent;
        color: ${(props) => props.color || "#2d4185"};
      }
    }
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 2em;
  }
  h6 {
    color: rgb(128, 128, 128);
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #726e6e;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  h5 {
    color: rgb(128, 128, 128);
    font-size: 13px;
    font-weight: 500;
    color: #111;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 60px;
    grid-template-rows: auto;
    border-bottom: 1px solid #dfe0eb;
  }
  input {
    min-width: 70px;
    margin: 1em 0.5em 1em 0;
    width: calc(100% - 0.5em);
  }
  label {
    display: none;
  }
  button {
    margin: 1em 0;
    width: 100% !important;
  }
  select {
    width: calc(100% - 0.5em);
    outline: none;
    padding: 10px 5px;
    font-size: 13px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    height: min-content;
    margin: 1em 0.5em 1em 0;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
    }
  }
  p {
    font-size: 11px;
    color: red;
    font-weight: 600;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    button {
      margin-top: 0;
      margin-bottom: 2.2em;
    }
  }
  .confirm {
    background: transparent;
    border-radius: 7px;
    border: 1.5px solid #187bcd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-form {
    input,
    select {
      border: 1.5px solid #187bcd;
    }
  }
`;
