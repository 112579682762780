import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import AdminContext from "../contexts/AdminContext";

const ViewApi = () => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { admin } = useContext(AdminContext);
  const [emails, setEmails] = useState([]);
  const history = useHistory();

  const outputJson = (data) => {
    delete data._id;
    delete data.__v;

    const obj = data.tags.reduce(
      (o, key) => Object.assign(o, { [key]: "String" }),
      {}
    );
    const newobject = {
      source: data.source,
      tags: obj,
    };
    return JSON.stringify(newobject, null, 2);
  };
  const location = useLocation();
  const { item } = location.state;

  useEffect(() => {
    setEmails(item?.emails);
  }, []);
  useEffect(() => {
    if (admin?.role?.includes("Clients") && !admin?.role?.includes("Leads")) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/complaints");
    }
  }, [admin]);

  return (
    <Layout>
      <Container>
        <h3 className="border">{`Api structure du site ${item.source}`}</h3>
        <div className="content-wrp">
          <div className="row">
            <h4 className="h4-header">Api link</h4>
            <input
              type="text"
              value={`${REACT_APP_BACKEND_URL}/leads/api-leads`}
              className="view-input"
              onChange={() => null}
              disabled
            />
          </div>
          <div className="row">
            <h4 className="h4-header">Api structure</h4>
            <div className="json-wrp">
              <pre>{item ? outputJson(item) : null}</pre>
            </div>
          </div>
          <div className="row">
            <h4 className="h4-header">Email list</h4>
            <div className="json-wrp">
              {emails.length !== 0 ? (
                emails.map((email, index) => {
                  return (
                    <pre key={`email-${index}`}>
                      <span className="span-tags">{email}</span>
                    </pre>
                  );
                })
              ) : (
                <p>pas de donneés</p>
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};
export default ViewApi;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 2em 0.5em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h3 {
    width: 100%;
    color: #868e96 !important;
    font-weight: 500;
    font-size: 16px;
  }
  .h4-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0.75em 0;
    min-width: 150px;
  }
  .border {
    border-bottom: 1px solid #e1e5eb !important;
    padding: 0.8em 1em;
  }
  .row {
    padding: 1em 0.5em;
    display: grid;
    grid-template-columns: 20% auto;
  }
  .view-input {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 7px;
    color: #808080;
    max-width: 300px;
  }
  .json-wrp {
    max-width: 600px;
    padding: 1em 4em;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 7px;
    color: #808080;
    height: fit-content;
  }
  pre {
    margin: 1em 0.5em;
  }
  .span-tags {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 14px;
    height: fit-content;
    background-color: #fff;
  }
  @media only screen and (max-width: 768px) {
    .row {
      grid-template-columns: 100% !important;
      grid-template-rows: auto auto;
    }
  }
`;
