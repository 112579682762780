import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import Layout from "../layouts/DefaultLayout";
import AdminContext from "../contexts/AdminContext";
import Pagination from "../components/Pagination";
import DeletePopUp from "../components/PopUps/DeletePopup";

const Complaints = () => {
  const [complaints, setComplaints] = useState([]);
  const [initialComplaints, setInitialComplaints] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");
  const { admin } = useContext(AdminContext);
  const history = useHistory();

  const [total, setTotal] = useState(1);
  const [popup, setPopup] = useState(0);
  const [currentPage, setCurrrentPage] = useState(1);
  const postsPerPage = 12;

  const getComplaints = async (page) => {
    const offest = (page - 1) * postsPerPage;

    try {
      const response = await fetch(REACT_APP_BACKEND_URL + `/compsTotal`, {
        method: "GET",
      });

      const res = await response.json();
      setTotal(res.total);

      const response2 = await fetch(
        REACT_APP_BACKEND_URL + `/complaints/${postsPerPage}/${offest}`,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res2 = await response2.json();
      setComplaints(res2);
      setInitialComplaints(res2);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteClient = async (id) => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/complaint/" + id, {
        method: "DELETE",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      if (res.message) {
        const arr = initialComplaints.filter(
          (item) => item.complaint_id !== id
        );
        setPopup(0);
        setComplaints(arr);
        setInitialComplaints(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      admin?.role?.includes("Clients") &&
      !admin?.role?.includes("Réclamations")
    ) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Réclamations")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Réclamations")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Réclamations")
    ) {
      history.push("/");
    }
  }, [admin]);

  useEffect(() => {
    getComplaints(1);
  }, []);

  return (
    <Layout>
      <Container>
        <SearchBar
          title="Liste des Réclamations"
          state={initialComplaints}
          setState={setComplaints}
          type="complaints"
          component="complaints"
        />
        <div className="table">
          <div className="grid">
            <h6>Prénom Nom</h6>
            <h6>Email</h6>
            <h6>Téléphone</h6>
            <h6>Sujet</h6>
            <h6 />
            {complaints.length !== 0 ? (
              complaints.map((item, index) => (
                <Fragment key={index}>
                  <h5 className="name">{item.complaint_name}</h5>
                  <h5>{item.complaint_email}</h5>
                  <h5>{item.complaint_phone} </h5>
                  <h5>{item.complaint_subject} </h5>
                  <h5 className="delete">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      onClick={() => setPopup(item.complaint_id)}
                    >
                      <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                    </svg>
                  </h5>
                </Fragment>
              ))
            ) : (
              <div className="not-found">
                <p>
                  <span className="red">*</span>pas de résultat
                </p>
              </div>
            )}
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            paginate={getComplaints}
            setCurrrentPage={setCurrrentPage}
            totalPosts={total}
            currentPage={currentPage}
          />
          {popup ? (
            <DeletePopUp
              deleteItem={() => deleteClient(popup)}
              cancleOperation={() => setPopup(0)}
              target="Supprimer réclamation"
              message="êtes-vous sûr de vouloir supprimer cette réclamation"
            />
          ) : null}
        </div>
      </Container>
    </Layout>
  );
};

export default Complaints;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    color: #726e6e;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    font-weight: 600;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 0.25fr;
    grid-template-rows: auto;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      fill: #f84b60;
      cursor: pointer;
    }
  }
  .not-found {
    padding: 1em;
    display: flex;
    align-items: center;
    p {
      color: #aaa;
    }
    .red {
      color: red;
      margin: 0 1em;
    }
  }
`;
