/* eslint-disable dot-notation */
import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { convertArrayToCSV } from "convert-array-to-csv";
import download from "downloadjs";
import SearchBar from "../components/SearchBar";
import Layout from "../layouts/DefaultLayout";
import AdminContext from "../contexts/AdminContext";
import Pagination from "../components/Pagination";
import DeletePopUp from "../components/PopUps/DeletePopup";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [initialClients, setInitialClients] = useState([]);
  const [forceupdate, setForceupdate] = useState(false);
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");
  const history = useHistory();
  const { admin } = useContext(AdminContext);

  const [popup, setPopup] = useState(0);
  const [total, setTotal] = useState(1);
  const [currentPage, setCurrrentPage] = useState(1);
  const postsPerPage = 12;

  const getClients = async (page) => {
    const offest = (page - 1) * postsPerPage;

    try {
      const response = await fetch(REACT_APP_BACKEND_URL + `/clientsTotal`, {
        method: "GET",
      });

      const res = await response.json();
      setTotal(res.total);

      const response2 = await fetch(
        REACT_APP_BACKEND_URL + `/clients/${postsPerPage}/${offest}`,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res2 = await response2.json();
      setClients(res2);
      setInitialClients(res2);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteClient = async (id) => {
    console.log("inside");
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/client/" + id, {
        method: "DELETE",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      if (res.message) {
        // alert("Le client a été supprimer avec succès");
        console.log("done");
        const arr = initialClients.filter((item) => item.user_id !== id);
        setPopup(0);
        setClients(arr);
        setInitialClients(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateStatus = async (id, status) => {
    try {
      const secondRes = await fetch(
        REACT_APP_BACKEND_URL + "/clientStatus/" + id,
        {
          method: "PUT",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: !status }),
        }
      );

      const res = await secondRes.json();
      if (res.message) {
        const arr = clients;
        arr.map((item) => {
          if (item.user_id === id) {
            item.user_status = !status;
          }
        });
        setClients(arr);
        setForceupdate(!forceupdate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Clients")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Clients")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Clients")
    ) {
      history.push("/complaints");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Clients")
    ) {
      history.push("/");
    }
  }, [admin]);

  const exportCsv = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + `/clients`, {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      res.map((item) => {
        delete item["user_pwd"];
      });
      const csv = convertArrayToCSV(res);
      download(csv, "data.csv", "application/csv");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClients(1);
  }, []);

  return (
    <Layout>
      <Container>
        <SearchBar
          title="Liste des clients"
          link="/add-client"
          btnText="Ajouter Contact"
          state={initialClients}
          setState={setClients}
          exportCsv={exportCsv}
          exprt
        />
        <div className="table">
          <div className="grid">
            <h6>Prénom Nom</h6>
            <h6>Email</h6>
            <h6>Téléphone</h6>
            <h6>Statut</h6>
            <h6 />
            {clients.length !== 0 ? (
              clients.map((client, index) => (
                <Fragment key={index}>
                  <h5 className="name">
                    {client.user_fname + " " + client.user_lname}
                  </h5>
                  <h5>{client.user_email}</h5>
                  <h5>{client.user_phone} </h5>
                  <h5>
                    <button
                      className={client.user_status ? "btn green" : "btn red"}
                      onClick={() =>
                        updateStatus(client.user_id, client.user_status)
                      }
                    >
                      {client.user_status ? "Activer" : "Non Activer"}
                    </button>
                  </h5>
                  <h5 className="icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="#f84b60"
                      onClick={() => setPopup(client.user_id)}
                    >
                      <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      className="view"
                      fill="#777"
                      onClick={() =>
                        history.push({
                          pathname: "/edit-client",
                          state: client.user_id,
                        })
                      }
                    >
                      <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z" />
                    </svg>
                  </h5>
                </Fragment>
              ))
            ) : (
              <div className="not-found">
                <p>
                  <span className="red">*</span>pas de résultat
                </p>
              </div>
            )}
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            paginate={getClients}
            setCurrrentPage={setCurrrentPage}
            totalPosts={total}
            currentPage={currentPage}
          />
          {popup ? (
            <DeletePopUp
              deleteItem={() => deleteClient(popup)}
              cancleOperation={() => setPopup(0)}
              target="Supprimer compte Client"
              message="êtes-vous sûr de vouloir supprimer ce client"
            />
          ) : null}
        </div>
      </Container>
    </Layout>
  );
};

export default Clients;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    color: #726e6e;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    font-weight: 600;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 0.25fr;
    grid-template-rows: auto;
  }
  .btn {
    background: #f84b60;
    color: #fff;
    padding: 5px 7px;
    border-radius: 7px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    justify-content: center;
    cursor: pointer;
  }
  .green {
    background: #56e78e;
  }
  .icons {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
  .not-found {
    padding: 1em;
    display: flex;
    align-items: center;
    p {
      color: #aaa;
    }
    .red {
      color: red;
      margin: 0 1em;
    }
  }
`;
