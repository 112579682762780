import React from "react";
import styled from "styled-components";
import { ReactComponent as DeleteIcone } from "../../assets/remove.svg";

const ViewElements = ({ error, values, handleClick, title, strict }) => {
  const staticTags = [
    "ville",
    "email",
    "nom",
    "marque",
    "origine",
    "plateforme",
    "modele",
    "telephone",
    "campagne",
  ];
  return (
    <Container>
      <h4 className="h4-header">{title}</h4>
      <div className="text-area">
        {values
          ? values.map((tag, index) => {
              return (
                <div className="span-tags-wrp" key={index}>
                  <span className="span-tags" key={index}>
                    {tag}
                  </span>
                  {!strict && !staticTags.includes(tag) ? (
                    <DeleteIcone onClick={() => handleClick(tag)} />
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </Container>
  );
};

export default ViewElements;

const Container = styled.div`
  .h4-header {
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
    margin: 1em 0;
  }
  .text-area {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    color: #808080;
    min-width: 200px;
    display: flex;
    flex-wrap: wrap;
    max-height: 150px;
    min-height: 100px;
    overflow-y: scroll;
  }
  .span-tags {
    padding: 5px 10px;
    margin: 0.25em 0.5em;
    border-radius: 10px;
    font-size: 14px;
    height: fit-content;
    //background-color: #fff;
    background-color: #f1f1f1;
  }
  .span-tags-wrp {
    position: relative;
    margin: 0.5em 0;
    svg {
      width: 18px;
      height: 18px;
      position: absolute;
      right: 0;
      top: -10px;
      cursor: pointer;
      display: none;
    }
    &:hover svg {
      display: block;
    }
  }
`;
