import styled from "styled-components";

const Popup = ({ setView, title, item }) => {
  return (
    <Container>
      {title === "Simulation" ? (
        <div className="simulation">
          <h2>{title}</h2>
          <hr />
          <h3>Client: </h3>
          <p className="info">{item.simulation_name} </p>
          <p className="label">Nom et prénom:</p>
          <p className="info">{item.simulation_profession}</p>
          <p className="label">Profession: </p>
          <p className="info">{item.simulation_city}</p>
          <p className="label">Ville: </p>
          <p className="info">{item.simulation_phone}</p>
          <p className="label">Téléphone: </p>
          <p className="info">{item.simulation_email}</p>
          <p className="label">Email: </p>
          <h3>Simulation: </h3>
          <p className="info">{item.simulation_credit_type}</p>
          <p className="label">Type de crédit: </p>
          <p className="info">
            {new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "MAD",
            }).format(item.simulation_global_cost)}{" "}
          </p>
          <p className="label">Montant: </p>
          <p className="info">{item.simulation_apport} %</p>
          <p className="label">Apport: </p>
          <p className="info">{item.simulation_duration} Mois</p>
          <p className="label">Duration: </p>
          <p className="info">
            {new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "MAD",
            }).format(item.simulation_monthly_payment)}
          </p>
          <p className="label">Mensualité: </p>
          <p className="info">{item.simulation_adi}</p>
          <p className="label">ADI: </p>
        </div>
      ) : (
        <div className="Rdv">
          <h2>{title}</h2>
          <hr />
          <h3>{item.rv_type} </h3>
          <p className="info">{item.rv_name}</p>
          <p className="label">Nom et prénom: </p>
          <p className="info">{item.rv_phone}</p>
          <p className="label">Téléphone: </p>
          <p className="info">{item.rv_email}</p>
          <p className="label">Email: </p>
          <p className="info">{item.rv_date}</p>
          <p className="label">Date: </p>
        </div>
      )}
      <button
        className="popup-btn"
        onClick={() => {
          setView(0);
        }}
      >
        Fermer
      </button>
    </Container>
  );
};

export default Popup;

const Container = styled.div`
  max-width: 400px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  svg {
    fill: #2d4185;
  }
  h2 {
    font-size: 28px;
    color: #2d4185;
  }
  h3 {
    margin-top: 2em;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 14px;
    color: #444;
    font-weight: 500;
  }
  .popup-btn {
    padding: 12px 28px;
    border-radius: 4px;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 2em;
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    background: #f84b60;
    &:hover {
      filter: brightness(95%);
    }
  }
  @media only screen and (max-width: 576px) {
    max-width: 330px;
  }
  .info {
    float: right;
  }
`;
