import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import ThemeContext from "../contexts/ThemeContext";
import Button from "../components/elements/Button";
import Csp from "../components/Csp";
import Segments from "../components/Segments";
import AdminContext from "../contexts/AdminContext";

const EditCredit = () => {
  const [newCredit, setNewCredit] = useState("");
  const [loading, setLoading] = useState(0);
  const [credit, setCredit] = useState(null);
  const [forceupdate, setForceupdate] = useState(0);
  const [vehicle, setVehicle] = useState([]);
  const [client, setClient] = useState([]);
  const [credits, setCredits] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [clientCategories, setClientCategories] = useState([]);
  const { theme } = useContext(ThemeContext);
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");
  const { admin } = useContext(AdminContext);
  const history = useHistory();

  const getCredits = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/credits", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setCredits(res);
      setCredit(res[0].credit_id);
      await getEligibility(res[0].credit_id);
    } catch (err) {
      console.log(err);
    }
  };

  const getVehicleTypes = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/vehicleTypes", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setVehicleTypes(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getClientCategories = async () => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/clientCategories",
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setClientCategories(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getEligibility = async (id) => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/eligibility/" + id,
        {
          method: "GET",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setVehicle(res.vehicles);
      setClient(res.clients);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeVehicle = async (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;

    let arr = [];
    if (isChecked) {
      arr = vehicle;
      arr.push(item);
    } else if (vehicle.includes(item)) {
      arr = vehicle.filter((vhc) => vhc !== item);
    }
    setVehicle(arr);
    setForceupdate(!forceupdate);
  };

  const handleChangeClient = async (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;

    let arr = [];
    if (isChecked) {
      arr = client;
      arr.push(item);
    } else if (client.includes(item)) {
      arr = client.filter((vhc) => vhc !== item);
    }
    setClient(arr);
    setForceupdate(!forceupdate);
  };

  const update = async () => {
    setLoading(1);
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/eligibility", {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          creditId: credit,
          vehicles: vehicle,
          clients: client,
        }),
      });

      const res = await response.json();
      console.log(res);
      setLoading(0);
    } catch (err) {
      setLoading(0);
      console.log(err);
    }
  };

  const addCredit = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/credit", {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: newCredit,
          id: theme.id,
        }),
      });

      const res = await response.json();
      if (res) {
        alert("Le Credit a été ajouté avec succès");
        getCredits();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      admin?.role?.includes("Clients") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/complaints");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/");
    }
  }, [admin]);

  useEffect(() => {
    getCredits();
    getVehicleTypes();
    getClientCategories();
  }, []);

  return (
    <Layout>
      <Container color={theme?.color}>
        <div className="btns-row">
          <Link to="edit-credit">
            <button className="active">Config du Crédit</button>
          </Link>
          <Link to="/edit-simulation">
            <button>Config des Params</button>
          </Link>
          <Link to="/add-scenario">
            <button>Config des Calculs</button>
          </Link>
        </div>
        <div className="grid">
          <Csp />
          <Segments />
        </div>

        <div className="credits">
          <div className="credit-row">
            <div className="select-wrp">
              <h4>Type de Credit:</h4>
              <select
                onChange={(e) => {
                  setCredit(e.target.value);
                  getEligibility(e.target.value);
                }}
              >
                {credits.map((item, index) => (
                  <option value={item.credit_id} key={index}>
                    {item.credit_type}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-row">
              <input
                className="input"
                name="client"
                id="client"
                type="text"
                placeholder="Credit"
                value={newCredit}
                onChange={(e) => setNewCredit(e.target.value)}
              />
              <Button
                handleClick={() => addCredit()}
                title="Ajouter"
                margin="0"
                radius="7px"
                padding="6px 22px"
              />
            </div>
          </div>
          <div className="checkboxs">
            <div className="grid-col">
              <h5>Segment de véhicules:</h5>
              {vehicleTypes.map((item, index) => (
                <div className="checkbox-row" key={index}>
                  <input
                    type="checkbox"
                    id={`vehicle-${index}`}
                    name={item.vehicleType_type}
                    checked={vehicle.includes(item.vehicleType_type)}
                    onChange={(e) => handleChangeVehicle(e)}
                  />
                  <label htmlFor={`vehicle-${index}`}>
                    {item.vehicleType_type}
                  </label>
                </div>
              ))}
            </div>
            <div className="grid-col">
              <h5>Catégorie de clients:</h5>
              {clientCategories.map((item, index) => (
                <div className="checkbox-row" key={index}>
                  <input
                    type="checkbox"
                    id={`client-${index}`}
                    name={item.clientCategory_name}
                    checked={client.includes(item.clientCategory_name)}
                    onChange={(e) => handleChangeClient(e)}
                  />
                  <label htmlFor={`client-${index}`}>
                    {item.clientCategory_name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <Button
            handleClick={() => update()}
            title={loading ? "Enregistrer..." : "Enregistrer"}
            radius="7px"
            margin="0"
            font="14px"
            padding="8px 22px"
          />
        </div>
      </Container>
    </Layout>
  );
};

export default EditCredit;

const Container = styled.div`
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-column-gap: 2em;
  }
  .btns-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    flex-wrap: wrap;
    button {
      font-family: inherit;
      font-size: 15px;
      width: 200px;
      font-weight: 500;
      background: transparent;
      color: ${(props) => props.color || "#2d4185"};
      padding: 8px 24px;
      border-radius: 100px;
      border: 2px solid ${(props) => props.color || "#2d4185"};
      cursor: pointer;
      transition: all 0.5s;
      margin: 0.5em 0.5em;
      &:hover {
        background: ${(props) => props.color || "#2d4185"};
        color: #fff;
      }
    }
    .active {
      background: ${(props) => props.color || "#2d4185"};
      border: 2px solid ${(props) => props.color || "#2d4185"};
      color: #fff;
      &:hover {
        background: transparent;
        color: ${(props) => props.color || "#2d4185"};
      }
    }
  }
  .credits {
    border-radius: 20px;
    background: #fff;
    padding: 2em;
    box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
      rgb(237 239 247 / 47%) 0px 0px 0px;
  }
  .credit-row {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .select-wrp {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
  }
  .credits {
    h4 {
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      margin-right: 2em;
      margin: 0.5em 2em 0.5em 0;
    }
    h5 {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      margin-right: 2em;
      margin-bottom: 1em;
      color: #2d4185;
    }
  }
  select {
    outline: none;
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    min-width: ${(props) => (props.small ? "170px" : "260px")};
    margin-bottom: 0.35em;
    max-width: ${(props) => (props.small ? "170px" : "unset")};
  }
  .checkboxs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 3em;
    grid-gap: 2em;
  }
  .checkbox-row {
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    margin-left: 1em;
  }
  label {
    margin-left: 1em;
    font-size: 14px;
    color: #888;
  }
  .grid-col {
    margin-bottom: 3em;
  }
  input[type="text"] {
    width: 320px;
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    margin-right: 1em;
  }
  .input-row {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 576px) {
    .checkboxs {
      display: block;
    }
    .grid {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
`;
