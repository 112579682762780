import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Layout from "../layouts/DefaultLayout";
import ThemeContext from "../contexts/ThemeContext";
import SearchBar from "../components/SearchBar";
import AdminContext from "../contexts/AdminContext";
import Pagination from "../components/Pagination";

const ListLeadsCsv = () => {
  const { theme } = useContext(ThemeContext);
  const { admin } = useContext(AdminContext);
  const [initialList, setInitialList] = useState([]);
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrrentPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [fetching, setFetch] = useState(0);
  const history = useHistory();
  const { REACT_APP_BACKEND_URL } = process.env;
  const postsPerPage = 10;

  const getLeadsCsv = async (page, source) => {
    setFetch(1);
    const offest = (page - 1) * postsPerPage;
    let query1 = null;
    let query2 = null;
    if (theme?.name?.includes("Autohall")) {
      query1 = `${REACT_APP_BACKEND_URL}/leads/get-totalrecords/`;
      query2 = `${REACT_APP_BACKEND_URL}/leads/get-records/${postsPerPage}/${offest}`;
    }
    try {
      const response = await fetch(query1, {
        method: "GET",
      });
      const res = await response.json();
      setTotal(res.total);
      console.log(res);

      const response2 = await fetch(query2, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res2 = await response2.json();
      setLeads(res2);
      setInitialList(res2);
      console.log("leads list records", res2);
      setFetch(0);
    } catch (err) {
      console.log;
      err;
    }
  };

  useEffect(() => {
    if (typeof theme !== "undefined" && theme !== null) {
      getLeadsCsv(1, "none");
    }
  }, [theme]);

  useEffect(() => {
    if (
      admin?.role?.includes("Clients") &&
      !admin?.role?.includes("Rendez-vous")
    ) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Rendez-vous")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Rendez-vous")
    ) {
      history.push("/complaints");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Rendez-vous")
    ) {
      history.push("/");
    }
  }, [admin]);

  return (
    <Layout>
      <Container brand={theme?.name}>
        <SearchBar
          title="Liste des Leads Csv"
          state={initialList}
          setState={setLeads}
          type="lead"
          component="lead"
        />
        <div className="table">
          <div className="grid">
            <div className="grid-row">
              <h6>Nom</h6>
              <h6>Email</h6>
              <h6>Téléphone</h6>
              <h6>Ville</h6>
              <h6>Marque</h6>
            </div>

            {fetching !== 0 ? (
              <div className="fetching">
                <svg
                  version="1.1"
                  id="loader-1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 50 50"
                  enableBackground="new 0 0 50 50;"
                >
                  <path
                    fill="#000"
                    d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                  >
                    <animateTransform
                      attributeType="xml"
                      attributeName="transform"
                      type="rotate"
                      from="0 25 25"
                      to="360 25 25"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </div>
            ) : leads.length !== 0 ? (
              leads.map((item, index) => (
                <div key={index} className="grid-row">
                  <h5>{item.nom ? item.nom : "---"}</h5>
                  <h5>{item.email ? item.email : "---"}</h5>
                  <h5>{item.telephone ? item.telephone : "---"}</h5>
                  <h5>{item.ville ? item.ville : "---"}</h5>
                  <h5>{item.marque ? item.marque : "---"}</h5>
                </div>
              ))
            ) : (
              <div className="not-found">
                <p>
                  <span className="red">*</span>pas de résultat
                </p>
              </div>
            )}
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            paginate={getLeadsCsv}
            setCurrrentPage={setCurrrentPage}
            totalPosts={total}
            currentPage={currentPage}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default ListLeadsCsv;
const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    color: #726e6e;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: flex;
    flex-direction: column;
    min-width: 1200px;
    .grid-row {
      display: grid;
      grid-template-columns: ${(props) =>
        props.brand === "Autohall"
          ? "1fr 1fr 1fr 1fr 1fr "
          : "1fr 1fr 1fr 1fr 1fr"};
    }
  }
  .not-found,
  .fetching {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #aaa;
    }
    .red {
      color: red;
      margin: 0 1em;
    }
  }
`;
