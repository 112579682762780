import React, { Fragment } from "react";
import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import Button from "../elements/Button";

const LeadDT = ({ item, setView }) => {
  return (
    <Container>
      <div className="popup-content">
        <div className="simulation">
          <h2>{item?.values?.nom}</h2>
          <hr />

          {item &&
            Object.entries(item?.values)?.map((i, index) => {
              return (
                <div key={`popup-dt-${index}`} className="p-row-dt">
                  <p className="label">{i[0]}</p>
                  <p className="info">{i[1] ? i[1] : "..."} </p>
                </div>
              );
            })}
        </div>
        <button
          className="popup-btn"
          onClick={() => {
            setView(null);
          }}
        >
          Fermer
        </button>
      </div>
    </Container>
  );
};

export default LeadDT;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  margin: 0;
  .popup-content {
    max-width: 400px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 3em 2em;
    display: flex;
    flex-direction: column;
  }
  svg {
    fill: #2d4185;
  }
  h2 {
    font-size: 28px;
    color: #2d4185;
  }
  hr {
    margin-bottom: 2em;
  }
  h3 {
    margin-top: 2em;
    margin-bottom: 0.5em;
  }
  .p-row-dt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.25em 0;
  }
  p {
    font-size: 14px;
    color: #444;
    font-weight: 500;
    text-transform: capitalize;
  }
  .popup-btn {
    padding: 12px 28px;
    border-radius: 4px;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 2em;
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    background: #f84b60;
    &:hover {
      filter: brightness(95%);
    }
  }
  @media only screen and (max-width: 576px) {
    max-width: 330px;
  }
  .info {
    float: right;
  }
`;
