import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import Button from "../components/elements/Button";
import { ReactComponent as DeleteIcone } from "../assets/remove.svg";
import StyledAddInput from "../components/elements/StyledAddInput";
import ViewElements from "../components/elements/ViewElements";
import AdminContext from "../contexts/AdminContext";
import ThemeContext from "../contexts/ThemeContext";

const EditSites = () => {
  const location = useLocation();
  const history = useHistory();
  const token = localStorage.getItem("admin");
  const { item } = location.state;
  const { admin } = useContext(AdminContext);
  const { theme } = useContext(ThemeContext);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [tags, settags] = useState([]);
  const [tag, settag] = useState("");
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [empty, setEmpty] = useState({ first: false, second: false });
  const [submitting, setSubmitting] = useState(0);

  const staticTags = [
    "ville",
    "email",
    "nom",
    "marque",
    "origine",
    "plateforme",
    "modele",
    "telephone",
    "campagne",
  ];

  const changetags = async (id) => {
    if (emails.length !== 0 && tags.length !== 0) {
      setSubmitting(1);
      try {
        const response = await fetch(
          REACT_APP_BACKEND_URL + "/leads/update-site-structure",
          {
            method: "PUT",
            headers: {
              "x-access-token": token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id, tags, emails }),
          }
        );
        const res = await response.json();
        if (res) {
          setSubmitting(0);
          history.push("/leads-sites");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      tags.length === 0
        ? setEmpty({ ...empty, first: true })
        : setEmpty({ ...empty, first: false });
      emails.length === 0
        ? setEmpty({ ...empty, second: true })
        : setEmpty({ ...empty, second: false });
    }
  };
  const addEmails = () => {
    setEmails([...emails, email]);
  };
  const deleteEmail = (email) => {
    setEmails(emails.filter((item) => item !== email));
  };
  const addtags = () => {
    settags([...tags, tag]);
  };

  const deleteSpanTag = (tag) => {
    if (!staticTags.includes(tag)) {
      settags(tags.filter((item) => item !== tag));
    }
  };

  useEffect(() => {
    settags(item.tags);
    setEmails(item.emails);
  }, []);

  useEffect(() => {
    if (admin?.role?.includes("Clients") && !admin?.role?.includes("Leads")) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Leads")
    ) {
      history.push("/complaints");
    }
  }, [admin]);

  return (
    <Layout>
      <Container color={theme?.color}>
        <h3 className="border">{`Modifier le site ${item.source}`}</h3>
        <div className="parent-div">
          <StyledAddInput
            margin="0 0.5em"
            label="Ajouter un champ"
            name="champ"
            type="champ"
            value={tag}
            setAction={settag}
            handleClick={addtags}
            color={theme?.color}
          />
          <ViewElements
            title="Champs list"
            values={tags}
            handleClick={(tag) => deleteSpanTag(tag)}
          />
          {empty.first ? <p className="empty">Champs Obligatoire</p> : null}

          <StyledAddInput
            margin="0 0.5em"
            label="Ajouter un email"
            name="email"
            id="email"
            type="email"
            value={email}
            setAction={setEmail}
            handleClick={addEmails}
            color={theme?.color}
          />
          <ViewElements
            title="Champs list"
            values={emails}
            handleClick={(email) => deleteEmail(email)}
          />
          {empty.second ? <p className="empty">Champs Obligatoire</p> : null}
          <div className="btn-wrp">
            <Button
              handleClick={() => changetags(item._id)}
              title={submitting ? "Modifier..." : "Modifier"}
              radius="7px"
              padding="6px 22px"
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default EditSites;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  width: 100%;
  max-width: 600px;
  margin: 3em auto;
  padding: 1em 2em;

  .parent-div {
    padding: 2em 0;
  }
  h3 {
    width: 100%;
    color: #868e96 !important;
    font-weight: 500;
    font-size: 16px;
  }
  .border {
    border-bottom: 1px solid #e1e5eb !important;
    padding: 0.8em 1em;
  }
  .popup-row {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    padding: 1em 0;
  }

  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .empty {
    font-size: 11px;
    color: red;
    font-weight: 600;
    margin-top: 0.1em;
    margin-left: 0.2em;
  }
  @media only screen and (max-width: 600px) {
    .popup-row {
      display: grid;
      grid-template-columns: 100% !important;
      grid-template-rows: auto auto;
      padding: 0 0 0.75em 0;
    }
  }
`;
