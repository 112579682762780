import React, { useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";

const DashGraph = ({ state, totals, dates }) => {
  const dummy = [];
  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: dates || dummy,
    },
  };

  return (
    <Container>
      {!state ? (
        <Chart
          options={options}
          series={totals || dummy}
          type="area"
          height={300}
          className="hello"
        />
      ) : (
        <div className="loader" title="2">
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            enableBackground="new 0 0 50 50;"
          >
            <path
              fill="#000"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </Container>
  );
};

export default DashGraph;

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  margin: 0.5em 0.25em;
  padding: 2em 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #f84b60;
    }
  }
  .apexcharts-toolbar {
    display: none;
  }
  .hello {
    width: 100%;
    .apexcharts-canvas,
    .apexchartsac2yfjtyk {
      width: 100% !important;
      svg {
        width: 100% !important;
      }
      foreignObject {
        width: 100% !important;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    padding: 1em 0.25em;
  }
`;
