import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import ThemeContext from "../contexts/ThemeContext";
import Parametres from "../components/Parametres";
import AdminContext from "../contexts/AdminContext";

const EditSimulation = () => {
  const { theme } = useContext(ThemeContext);
  const { admin } = useContext(AdminContext);
  const history = useHistory();

  useEffect(() => {
    if (
      admin?.role?.includes("Clients") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Rendez-vous") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/rvs");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/complaints");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Simulateur")
    ) {
      history.push("/");
    }
  }, [admin]);

  return (
    <Layout>
      <Container color={theme?.color}>
        <div className="btns-row">
          <Link to="edit-credit">
            <button>Config du Crédit</button>
          </Link>
          <Link to="/edit-simulation">
            <button className="active">Config des Params</button>
          </Link>
          <Link to="/add-scenario">
            <button>Config des Calculs</button>
          </Link>
        </div>
        <Parametres />
      </Container>
    </Layout>
  );
};

export default EditSimulation;

const Container = styled.div`
  .btns-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    flex-wrap: wrap;
    button {
      font-family: inherit;
      font-size: 15px;
      width: 200px;
      font-weight: 500;
      background: transparent;
      color: ${(props) => props.color || "#2d4185"};
      padding: 8px 24px;
      border-radius: 100px;
      border: 2px solid ${(props) => props.color || "#2d4185"};
      cursor: pointer;
      transition: all 0.5s;
      margin: 0.5em 0.5em;
      &:hover {
        background: ${(props) => props.color || "#2d4185"};
        color: #fff;
      }
    }
    .active {
      background: ${(props) => props.color || "#2d4185"};
      border: 2px solid ${(props) => props.color || "#2d4185"};
      color: #fff;
      &:hover {
        background: transparent;
        color: ${(props) => props.color || "#2d4185"};
      }
    }
  }
`;
