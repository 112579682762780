import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Layout from "../layouts/DefaultLayout";
import LeadsHeader from "../components/leads/LeadsHeader";
import AdminContext from "../contexts/AdminContext";
import FeedBack from "../components/elements/FeedBack";
import Pagination from "../components/Pagination";

import DeletePopUp from "../components/PopUps/DeletePopup";

import LeadDT from "../components/PopUps/LeadDT";

const ListLeads = () => {
  const [popup, setPoUp] = useState(0);
  const [bySource, setBysource] = useState("");
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrrentPage] = useState(1);
  const [isDeleted, setIsDeleted] = useState(0);
  const [leadsList, setLeadsList] = useState([]);
  const [sources, setSources] = useState([]);
  const [filtredData, setFiltredData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [popUp, setPopup] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [visible, setVisible] = useState(-1);
  const { admin } = useContext(AdminContext);
  const history = useHistory();
  const { REACT_APP_BACKEND_URL } = process.env;
  const postsPerPage = 12;
  let isMounted = true;

  const getAllLeads = async (source) => {
    const resp = await fetch(
      `${REACT_APP_BACKEND_URL}/leads/getallleads/${source}`
    );
    const respJson = await resp.json();
    setLeadsList(respJson);
  };

  const getLeads = async (page, source) => {
    const offset = (page - 1) * postsPerPage;
    setLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/leads/get-leads/${postsPerPage}/${offset}/${source}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const response2 = await fetch(
        `${REACT_APP_BACKEND_URL}/leads/get-leads-total/${source}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const res = await response.json();
      const res2 = await response2.json();
      setLeadsList(res);
      setFiltredData(res);
      setTotal(res2);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteLead = async (id) => {
    let newArray = [];
    newArray = filtredData.filter((element) => {
      return element._id !== id;
    });
    setFiltredData(newArray);
    setLeadsList(newArray);
    setIsDeleted(1);

    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + `/leads/delete-lead/${id}`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        }
      );
      response
        .json()
        .then(function (data) {
          setIsDeleted(2);
          setTimeout(() => {
            setIsDeleted(0);
          }, 300);
        })
        .catch((err) => {
          console.log(err);
          setIsDeleted(-1);
          setTimeout(() => {
            setIsDeleted(0);
          }, 300);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDate = (olddate) => {
    const newdate = new Date(olddate);
    const year = newdate.getFullYear();
    let month = newdate.getMonth() + 1;
    let dt = newdate.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return `${year}-${month}-${dt}`;
  };

  const getSources = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/leads/get-source",
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      const array = [{ name: "Tous...", value: "all" }];
      Object.values(res).map((item, index) => {
        array.push({ name: item.source, value: item.source });
      });
      setSources(array);
      setBysource(array[0]?.value);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const filterData = (data) => {
    const filtredArray = [];
    setBysource(data);
    // if (data === "all") {
    //   setSearchArray(leadsList);
    //   setFiltredData(leadsList);
    // } else {
    //   leadsList.map((lead, index) => {
    //     if (data.toLowerCase() === lead.source.toLowerCase()) {
    //       if (typeof lead.values !== "undefined") {
    //         filtredArray.push(lead);
    //       }
    //     }
    //   });
    //   setSearchArray(filtredArray);
    //   setFiltredData(filtredArray);
    // }
  };

  const GetLeadbyname = async (terme, page) => {
    const offset = (page - 1) * postsPerPage;
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/leads/get-lead-by-name/${terme}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const res = await response.json();
      console.log(res);
      setLeadsList(res);
      setTotal(res.length);
    } catch (err) {
      console.log(err.message);
    }
  };

  // eslint-disable-next-line no-lone-blocks
  {
    /*
    useEffect(() => {
    if (isMounted && bySource) {
      getLeads(1, bySource);
    }
    return () => {
      isMounted = false;
    };
  }, [bySource]);
    */
  }
  useEffect(() => {
    getAllLeads(bySource);
  }, [bySource]);

  useEffect(() => {
    if (isMounted) {
      getSources();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (admin?.role === "Admin Clients") {
      history.push("/clients");
    } else if (admin?.role === "Admin Simulateur") {
      history.push("/simulations");
    } else if (admin?.role === "Admin Rendez-vous") {
      history.push("/rvs");
    } else if (admin?.role === "Admin Réclamations") {
      history.push("/complaints");
    } else if (admin?.role === "Leads") {
      history.push("/");
    }
  }, [admin]);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = leadsList.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrrentPage(pageNumber);
  console.log(currentPosts);

  const dataToShow = isSearch ? currentPosts : leadsList;

  return (
    <Layout>
      <Container>
        <LeadsHeader
          selectData={sources}
          title="Liste des leads"
          handleChange={filterData}
          state={filtredData}
          data={leadsList}
          setState={setLeadsList}
          parent="listleads"
          setTotal={setTotal}
          setData={setLeadsList}
          setIsSearch={setIsSearch}
          overide={(terme) => {
            GetLeadbyname(terme);
          }}
          isNull={(page) => getAllLeads(bySource)}
        />
        <div className="table">
          <div className="grid">
            <div className="row row-top">
              <h6>Nom</h6>
              <h6>Email</h6>
              <h6>Date</h6>
              <h6>Source</h6>
              <h6>Ville</h6>
              <h6>Telephone</h6>
              <h6>Plateforme</h6>
              <h6>Modele</h6>
              {/* <h6 /> */}
              <h6 />
            </div>
            {!loading ? (
              leadsList.length !== 0 ? (
                currentPosts.map((lead, index) => {
                  if (typeof lead.values !== "undefined") {
                    return (
                      <div className="row row-border" key={uuidv4()}>
                        <h5 className="data">
                          {lead?.values?.nom ? lead?.values?.nom : "..."}
                        </h5>
                        <h5 className="data">
                          {lead?.values?.email ? lead?.values?.email : "..."}
                        </h5>
                        <h5 className="data">{handleDate(lead.date)}</h5>
                        <h5 className="name">
                          {lead?.source ? lead?.source : "..."}
                        </h5>
                        <h5 className="name">
                          {lead?.values?.ville ? lead?.values?.ville : "..."}
                        </h5>
                        <h5 className="data">
                          {lead?.values?.telephone
                            ? lead?.values?.telephone
                            : "..."}
                        </h5>
                        <h5 className="data">
                          {lead?.values?.plateforme
                            ? lead?.values?.plateforme
                            : "..."}
                        </h5>
                        <h5 className="data">
                          {lead?.values?.modele ? lead?.values?.modele : "..."}
                        </h5>
                        <h5 className="delete">
                          <button
                            className="svg-btn"
                            onClick={() => setPopup(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#187bcd"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z" />
                            </svg>
                          </button>
                          <button
                            className="svg-btn"
                            onClick={() => setPoUp(lead._id)}
                          >
                            <svg
                              fill="#f84b60"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                            </svg>
                          </button>
                        </h5>
                        {popup ? (
                          <DeletePopUp
                            deleteItem={() => {
                              deleteLead(popup);
                              setPoUp(null);
                            }}
                            cancleOperation={() => setPoUp(null)}
                            target="Supprimer le lead"
                            message="êtes-vous sûr de vouloir supprimer ce lead"
                          />
                        ) : null}
                      </div>
                    );
                  }
                })
              ) : (
                <p className="no-result-msg">
                  <span className="red">*</span>aucun resultat trouver
                </p>
              )
            ) : (
              <div className="loading-div">
                <svg
                  version="1.1"
                  id="loader-1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 50 50"
                  enableBackground="new 0 0 50 50;"
                >
                  <path
                    fill="#000"
                    d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                  >
                    <animateTransform
                      attributeType="xml"
                      attributeName="transform"
                      type="rotate"
                      from="0 25 25"
                      to="360 25 25"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </div>
            )}
          </div>
        </div>
        <Pagination
          source={bySource}
          postsPerPage={postsPerPage}
          paginate={paginate}
          setCurrrentPage={setCurrrentPage}
          totalPosts={leadsList.length}
          currentPage={currentPage}
          lead
        />
        {popUp !== null ? (
          <LeadDT setView={setPopup} item={leadsList[popUp]} />
        ) : null}
        {isDeleted !== 0 ? (
          <FeedBack
            text={
              isDeleted === 1
                ? "suppression de l'utilisateur"
                : isDeleted === 2
                ? "Utilisateur supprimer"
                : "Une erreur est survenue"
            }
            state={isDeleted}
            setState={setIsDeleted}
            status={isDeleted}
          />
        ) : null}
      </Container>
    </Layout>
  );
};
export default ListLeads;

const Container = styled.div`
  border-radius: 20px;
  padding: 1em 2em 0em 2em;

  .table {
    overflow-x: auto;
    padding-bottom: 2.5em;
    .row {
      min-width: 600px;
    }
  }
  .no-result-msg {
    color: #808080;
    font-size: 13px;
    margin: 0 auto 0.25em 1em;
    padding: 0.5em;
    .red {
      color: red;
      margin: 0 0.25em;
    }
  }
  .row {
    display: grid;
    grid-template-columns: 100px 200px repeat(9, minmax(120px, 1fr)) 90px;
    position: relative;
  }
  .vertical-content {
    position: absolute;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    background-color: #f1f1f1;
    border-radius: 7px;
    box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
      rgb(237 239 247 / 47%) 0px 0px 0px;
    h5 {
      padding: 1em;
    }
    .vertical-content-row {
      margin: 0 0.5em;
      display: grid;
      grid-template-columns: 0.5fr 1.5fr;
    }
  }
  .row-values {
    display: flex;
    justify-content: flex-start;
    margin-right: 1em;
    h5 {
      margin: 0 1em 0 0;
    }
  }
  .name {
    color: #252733;
    font-weight: 600 !important;
  }
  .data {
    color: #9fa2b4;
  }
  .row-border {
    border-bottom: 1px solid #dfe0eb;
    width: 100%;
  }
  .row-top {
    border-bottom: 2px solid #dfe0eb;
    h6 {
      color: #676767;
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    padding: 1em 0.5em 1em 0;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
  }
  .svg-btn {
    background-color: transparent;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
  .p-tags {
    margin: 0.25em 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #bdbdbd;
    .red {
      color: red;
      margin: 1em 0.5em;
    }
  }
  .loading-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
  @media only screen and (max-width: 576px) {
    .vertical-content {
      min-width: 300px;
      .vertical-content-row {
        grid-template-columns: 0.75fr 1fr !important;
      }
    }
  }
`;
