/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import styled from "styled-components";
import Button from "../components/elements/Button";
import Layout from "../layouts/DefaultLayout";
import CustomInput from "../components/elements/CustomInput";
import SuccessPopUp from "../components/PopUps/SuccessPopup";
import AdminContext from "../contexts/AdminContext";

const AddAdmin = () => {
  const [brands, setBrands] = useState([]);
  const [roles, setRoles] = useState([]);
  const [popup, setPopup] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [error, setError] = useState(null);
  const { REACT_APP_BACKEND_URL } = process.env;
  const history = useHistory();
  const token = localStorage.getItem("admin");
  const { admin } = useContext(AdminContext);

  const getBrands = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/brands", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setBrands(res);
    } catch (err) {
      console.log(err);
    }
  };

  const addAdmin = async (data) => {
    if (roles.length) {
      let role = null;
      if (roles.includes("Admin")) {
        role = "Admin";
      } else {
        role = "Admin " + roles.join(" + ");
      }

      try {
        const response = await fetch(REACT_APP_BACKEND_URL + "/admin", {
          method: "POST",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
            brand: admin.brand != 1 ? admin.brand : data.brand,
            role: role,
          }),
        });
        console.log(role);
        const res = await response.json();
        if (res.message) {
          setPopup(1);
          setTimeout(() => {
            setPopup(0);
            history.push("/admins");
          }, 1500);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setError("Champs Obligatoire");
    }
  };

  const add = (role) => {
    let arr = roles;
    if (arr.includes(role)) {
      arr = roles.filter((item) => item !== role);
    } else {
      arr.push(role);
    }
    setRoles(arr);
  };

  useEffect(() => {
    if (admin?.role?.includes("Clients")) {
      history.push("/clients");
    } else if (admin?.role?.includes("Simulateur")) {
      history.push("/simulations");
    } else if (admin?.role?.includes("Rendez-vous")) {
      history.push("/rvs");
    } else if (admin?.role?.includes("Réclamations")) {
      history.push("/complaints");
    } else if (admin?.role?.includes("Leads")) {
      history.push("/");
    }
  }, [admin]);

  useEffect(() => {
    const body = document.getElementById("root");

    body.addEventListener("click", (e) => {
      if (e.target.className !== "select" && e.target.className !== "option") {
        setIsActive(0);
      }
    });
  }, []);

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <Layout>
      <Container>
        <Formik
          initialValues={{
            email: "",
            pwd: "",
            username: "",
            brand: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Email invalid")
              .required("Champs Obligatoire"),
            pwd: Yup.string()
              .required("Champs Obligatoire")
              .min(6, "Doit comporter plus de 6 caractères"),
            username: Yup.string().required("Champs Obligatoire"),
            brand:
              admin.brand == 1
                ? Yup.string().required("Champs Obligatoire")
                : Yup.string(),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            await addAdmin(data);
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            isSubmitting,
            errors,
            touched,
          }) => (
            <Form className="form">
              <h3>Ajouter Admin</h3>
              <CustomInput
                margin="0 0.5em"
                label="Username"
                name="username"
                id="username"
                type="text"
                placeholder="Admin"
              />
              <CustomInput
                margin="0 0.5em"
                label="Email"
                name="email"
                id="email"
                type="text"
                placeholder="mohammed@gmail.com"
              />
              <CustomInput
                margin="0 0.5em"
                label="Mot de passe"
                name="pwd"
                id="pwd"
                type="password"
                placeholder="Mot de passe"
              />
              {admin.brand == 1 ? (
                <div className="select-wrp">
                  <label>Pour</label>
                  <select
                    name="brand"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Choisir...</option>
                    {brands.map((item, index) => (
                      <option key={index} value={item.brand_id}>
                        {item.brand_name}
                      </option>
                    ))}
                  </select>
                  {errors.brand && touched.brand ? (
                    <p className="error">{errors.brand}</p>
                  ) : null}
                </div>
              ) : null}
              <div className="select-container">
                <label>Rôle</label>
                <button
                  className="select"
                  type="button"
                  onClick={() => setIsActive(!isActive)}
                >
                  {roles.length === 0
                    ? " Choisir..."
                    : roles.map((item, index) =>
                        roles.length > index + 1 ? `${item} + ` : item
                      )}
                  <svg
                    width="12"
                    height="6"
                    viewBox="0 0 20 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow"
                  >
                    <path
                      d="M2 2L10 10L18 2"
                      stroke="#111"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                {isActive ? (
                  <div className="options">
                    <button
                      type="button"
                      className={
                        roles.includes("Admin") ? "option active" : "option"
                      }
                      onClick={() => {
                        add("Admin");
                        setIsActive(!isActive);
                      }}
                    >
                      Admin
                    </button>
                    <button
                      className={
                        roles.includes("Clients") ? "option active" : "option"
                      }
                      type="button"
                      onClick={() => {
                        add("Clients");
                        setIsActive(!isActive);
                      }}
                    >
                      Admin Clients
                    </button>
                    <button
                      className={
                        roles.includes("Simulateur")
                          ? "option active"
                          : "option"
                      }
                      type="button"
                      onClick={() => {
                        add("Simulateur");
                        setIsActive(!isActive);
                      }}
                    >
                      Admin Simulateur
                    </button>
                    <button
                      className={
                        roles.includes("Rendez-vous")
                          ? "option active"
                          : "option"
                      }
                      type="button"
                      onClick={() => {
                        add("Rendez-vous");
                        setIsActive(!isActive);
                      }}
                    >
                      Admin Rendez-vous
                    </button>
                    <button
                      className={
                        roles.includes("Leads") ? "option active" : "option"
                      }
                      type="button"
                      onClick={() => {
                        add("Leads");
                        setIsActive(!isActive);
                      }}
                    >
                      Admin Leads
                    </button>
                    <button
                      className={
                        roles.includes("Réclamations")
                          ? "option active"
                          : "option"
                      }
                      type="button"
                      onClick={() => {
                        add("Réclamations");
                        setIsActive(!isActive);
                      }}
                    >
                      Admin Réclamations
                    </button>
                  </div>
                ) : null}
                {error ? <p className="error">{error}</p> : null}
              </div>
              <div className="btn-wrp">
                <Button
                  handleClick={handleSubmit}
                  title={isSubmitting ? "Enregister..." : "Enregister"}
                  type="submit"
                  radius="7px"
                />
              </div>
            </Form>
          )}
        </Formik>
        {popup ? (
          <SuccessPopUp
            closePopup={() => {
              setPopup(0);
              history.push("/admins");
            }}
            message="L'Admin a été ajouté avec succès"
            target="Ajout Admin"
          />
        ) : null}
      </Container>
    </Layout>
  );
};

export default AddAdmin;

const Container = styled.div`
  background: #fff;
  width: 100%;
  max-width: 650px;
  margin: auto;
  margin-top: 2em;
  padding: 0em 0em 1.5em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  .form {
    padding: 2em;
  }
  .left-side {
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25vw;
    min-width: 350px;
    max-width: 420px;
    margin-bottom: 1em;
    height: fit-content;
    margin-right: 1.5em;
  }
  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    object-fit: cover;
  }
  .right-side {
    max-width: 750px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 1em;
  }

  input[type="email"],
  input[type="password"] {
    width: 100%;
  }
  input,
  textarea {
    width: 100% !important;
  }
  h3 {
    width: 100%;
    color: #868e96 !important;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #e1e5eb !important;
  }
  .profil {
    padding: 1.5em 2em 0.5em 2em;
  }
  form {
    width: 100%;
  }
  #file-input {
    display: none;
  }
  .file-label {
    margin-top: 1em;
  }
  .btn {
    position: relative;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border: 1px solid #f84b60;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    color: #f84b60;
    cursor: pointer;
    svg {
      margin-left: 5px;
    }
  }
  p {
    padding: 0.75em 1em;
    text-align: justify;
    color: #5a6169;
  }
  label {
    position: relative;
  }
  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #f84b60;
    }
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -65px;
    top: -5px;
    display: none;
    &:before {
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .active {
    display: block;
  }
  .hidden {
    width: 0;
    height: 0;
    position: absolute;
  }
  .error {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .avatar:hover + span {
    display: block;
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -110px;
    top: 35px;
    display: none;
    width: 100px;
    line-height: 1.5em;
    text-align: justify;
    &:before {
      top: 18px;
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
  }
  select,
  .select {
    outline: none;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    min-width: ${(props) => (props.small ? "170px" : "260px")};
    margin: 0.35em 0;
    max-width: ${(props) => (props.small ? "170px" : "unset")};
    background: transparent;
    text-align: left;
    position: relative;
  }
  .select svg {
    position: absolute;
    right: 0.2em;
    top: 1.25em;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
    margin-top: 0.1em;
    padding-left: 0;
  }
  .select-wrp {
    position: relative;
    margin-top: 1.2em;
  }
  .select-container {
    margin-top: 1.5em;
    position: relative;
  }
  .options {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 8px;
    max-width: 400px;
    position: absolute;
    top: 4.5em;
    left: 0;
  }
  .option {
    width: 100%;
    padding: 8px 10px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    color: #777;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
  }
  .option:last-child {
    border-bottom: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .option:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .active {
    background-color: #eee;
  }
  @media only screen and (max-width: 576px) {
    .sub-form {
      padding: 0 1em;
    }
  }
`;
