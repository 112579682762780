import React, { useState, useEffect, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";

const DashSitesCart = ({ data, state, setState }) => {
  const [content, setContent] = useState([]);
  const [total, setTotal] = useState(0);
  const selectData = ["Aujourd'hui", "Semaine", "Mois", "Année"];
  useEffect(() => {
    renderContent();
  }, [data]);

  const calculatePercentage = (total, part) => {
    if (part !== 0) {
      return (part * 100) / total;
    }
    return 0;
  };
  const leadsWebSitedate = [
    {
      color: "#27A343",
    },
    {
      color: "#D53243",
    },
    {
      color: "#0075F6",
    },
    {
      color: "#E7961E",
    },
  ];
  const renderContent = (index) => {
    let nb = 0;
    if (data) {
      if (index) {
        setContent(data[index]);
        data[index].map((item) => {
          nb += item.count;
        });
        setTotal(nb);
      } else {
        setContent(data[0]);
        data[0].map((item) => {
          nb += item.count;
        });
        setTotal(nb);
      }
    }
  };
  return (
    <Container className="parent-wrp">
      <div className="top">
        <h4 className="h4-top">Sites spécialiser</h4>
        <select
          name="time"
          id="time"
          value={state}
          onChange={(e) => {
            renderContent(e.target.value);
            setState(parseInt(e.target.value, 10));
          }}
        >
          {selectData.map((time, index) => {
            return (
              <option value={index} key={`option-${index}`}>
                {time}
              </option>
            );
          })}
        </select>
      </div>
      <div className="content-wrpx">
        {content.length !== 0 ? (
          content.map((item, index) => {
            return (
              <Fragment key={uuidv4()}>
                <div className="row row-bottom">
                  <h4 className="name">{item.source}</h4>
                  <CustomHeader className="percentage" color="#bdbdbd">
                    {new Intl.NumberFormat("fr-FR").format(
                      calculatePercentage(total, item.count)
                    )}
                    %
                  </CustomHeader>
                  <h4 className="number">{item.count}</h4>
                </div>
              </Fragment>
            );
          })
        ) : (
          <div className="loader" title="2">
            <svg
              version="1.1"
              id="loader-1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="40px"
              height="40px"
              viewBox="0 0 50 50"
              enableBackground="new 0 0 50 50;"
            >
              <path
                fill="#000"
                d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.6s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
        )}
      </div>
    </Container>
  );
};
export default DashSitesCart;

const Container = styled.div`
  margin: 0.5em;
  padding: 2em 1.5em;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 0;
    .h4-top {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #3d3d3d;
    }
    select {
      outline: none;
      border: 1px solid #c4c4c4;
      padding: 0.25em 0.5em;
      border-radius: 7px;
      color: #808080;
    }
  }
  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #f84b60;
    }
  }
  .content-wrpx {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em 0 0.25em 0;
    overflow-y: scroll;
    max-height: 300px;
    .row-bottom {
      border-bottom: 1px solid #e0e0e0;
    }
    .row {
      padding: 1em 0;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 40px;
      .name,
      .number {
        color: #3d3d3d;
      }
      .percentage {
        font-weight: 600;
      }
      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .top {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      select {
        margin-top: 0.5em;
        width: 100%;
      }
    }
    .row {
      grid-template-columns: 1fr 1fr 40px !important;
    }
  }
`;
const CustomHeader = styled.h4`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => (props.color ? props.color : "#3d3d3d")};
`;
