import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as FailedIcone } from "../../assets/close.svg";
import { ReactComponent as SuccessIcone } from "../../assets/check.svg";

const FeedBack = ({ text, state, setState, status }) => {
  const [isActive, setIsActive] = useState(1);

  // setTimeout(function () {
  //   setIsActive(0);
  // }, 1000);
  return (
    <Container isActive={isActive}>
      <span className="feedback-message">{text}</span>
      {status === 1 ? (
        <svg
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 50 50"
          enableBackground="new 0 0 50 50;"
        >
          <path
            fill="#fff"
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      ) : status === 2 ? (
        <SuccessIcone />
      ) : (
        <FailedIcone />
      )}
    </Container>
  );
};

export default FeedBack;

const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 1em;
  padding: 0.5em 0.5em 0.5em 1em;
  background-color: #222;
  border-radius: 7px;
  color: #fff;
  justify-content: space-around;
  align-items: center;
  display: flex;
  //display: ${(props) => (props.isActive ? "flex" : "none")};
  .feedback-message {
    color: #fff !important;
    font-size: 0.8rem;
    font-weight: 500;
    margin-right: 0.5em;
  }
  svg {
    width: 24px;
    height: 24px;
    margin: 0 0.5em;
  }
`;
