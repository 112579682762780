const CityList = [
  "Ville...",
  "Casablanca",
  "Fez",
  "Tangier[",
  "Marrakesh",
  "Salé",
  "Meknes",
  "Rabat",
  "Oujda",
  "Kenitra",
  "Agadir",
  "Tetouan",
  "Temara",
  "Safi",
  "Mohammedia",
  "Khouribga",
  "El Jadida",
  "Beni Mellal",
  "Aït Melloul",
  "Nador",
  "Dar Bouazza",
  "Taza",
  "Settat",
  "Berrechid",
  "Khemisset",
  "Inezgane",
  "Ksar El Kebir",
  "Larache",
  "Guelmim",
  "Khenifra",
  "Berkane",
  "Taourirt",
  "Bouskoura",
  "Fquih Ben Salah",
  "Dcheira El Jihadia",
  "Oued Zem",
  "El Kelaa Des Sraghna",
  "Sidi Slimane",
  "Errachidia",
  "Guercif",
  "Oulad Teima",
  "Ben Guerir",
  "Tifelt",
  "Lqliaa",
  "Taroudant",
  "Sefrou",
  "Essaouira",
  "Fnideq",
  "Sidi Kacem",
  "Tiznit",
  "Tan-Tan",
  "Ouarzazate",
  "Souk El Arbaa",
  "Youssoufia",
  "Lahraouyine",
  "Martil",
  "Ain Harrouda",
  "Suq as-Sabt Awlad an-Nama",
  "Skhirat",
  "Ouazzane",
  "Benslimane",
  "Al Hoceima",
  "Beni Ansar",
  "	M'diq",
  "Sidi Bennour",
  "Midelt",
  "Azrou",
  "Drargua",
];
export default CityList;
