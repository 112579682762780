import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "./elements/Button";

const Segment = () => {
  const [forceupdate, setForceupdate] = useState(0);
  const [itemId, setItemId] = useState(-1);
  const [updatedClient, setUpdatedClient] = useState("");
  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");

  const getVehicleTypes = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/vehicleTypes", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setClients(res);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteVehicleType = async (id) => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/vehicleType/" + id,
        {
          method: "DELETE",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (res.message) {
        alert("Le Segment a été supprimer avec succès");
        const arr = clients.filter((item) => item.vehicleType_id !== id);
        setClients(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addSegment = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/vehicleType", {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: client,
        }),
      });

      const res = await response.json();
      if (res) {
        const arr = clients;
        arr.push({
          vehicleType_type: client,
        });
        setClients(arr);
        setForceupdate(!forceupdate);
        alert("Le Segment a été ajouté avec succès");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateVehicleType = async (id) => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/vehicleType/" + id,
        {
          method: "PUT",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedClient,
          }),
        }
      );

      const res = await response.json();
      if (res) {
        const arr = clients;
        arr.map((item) => {
          if (item.vehicleType_id === id) {
            item.vehicleType_type = updatedClient;
          }
        });

        setClients(arr);
        setForceupdate(!forceupdate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVehicleTypes();
  }, []);

  return (
    <Container>
      <h4>Segment de véhicules</h4>
      <div className="row input-row">
        <input
          className="input"
          name="client"
          id="client"
          type="text"
          placeholder="Segment"
          value={client}
          onChange={(e) => setClient(e.target.value)}
        />
        <Button
          handleClick={() => addSegment()}
          title="Ajouter"
          margin="0"
          radius="7px"
          padding="6px 22px"
        />
      </div>
      <div className="segment">
        {clients.map((item, index) => (
          <div className="row rv-row" key={index}>
            {itemId === item.vehicleType_id ? (
              <input
                className="edit-input"
                name="client"
                id={`client-${item.vehicleType_id}`}
                type="text"
                placeholder="CSP"
                value={updatedClient}
                onChange={(e) => setUpdatedClient(e.target.value)}
                autoFocus
              />
            ) : (
              <h5>{item.vehicleType_type}</h5>
            )}
            <h5>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="#f84b60"
                onClick={() => deleteVehicleType(item.vehicleType_id)}
              >
                <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
              </svg>
              {itemId === item.vehicleType_id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="#187bcd"
                  onClick={() => {
                    updateVehicleType(item.vehicleType_id);
                    setItemId(-1);
                  }}
                >
                  <path d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="#777"
                  onClick={() => {
                    setItemId(item.vehicleType_id);
                    setUpdatedClient(item.vehicleType_type);
                  }}
                >
                  <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z" />
                </svg>
              )}
            </h5>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Segment;

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  padding: 1.5em 2em;
  margin-bottom: 2em;
  .segment {
    max-height: 240px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 1em;
  }
  input {
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    margin-right: 1em;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 70px;
    width: 100%;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: rgb(128, 128, 128);
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    position: relative;
  }
  svg {
    margin: 0 0.5em;
    cursor: pointer;
  }
  .rv-row {
    border-bottom: 1px solid #dfe0eb;
  }
  .input-row {
    display: grid !important;
    grid-template-columns: 1fr 120px;
    margin-bottom: 1.5em;
  }
  .edit-input {
    border: none !important;
    padding: 0 !important;
  }
  @media only screen and (max-width: 576px) {
    .input-row {
      grid-template-columns: 65% 35%;
    }
  }
`;
