import React, { createContext, useState, useEffect } from "react";
import jwt from "jsonwebtoken";

const AdminContext = createContext({});

export const AdminProvider = ({ children }) => {
  const [admin, setAdmin] = useState({});
  const { REACT_APP_SECRET } = process.env;

  const adminContext = {
    admin,
    setAdmin,
  };

  useEffect(() => {
    const storage = localStorage.getItem("admin");
    jwt.verify(storage, REACT_APP_SECRET, (err, decoded) => {
      if (decoded) {
        setAdmin(decoded);
      }
    });
  }, []);

  return (
    <AdminContext.Provider value={adminContext}>
      {children}
    </AdminContext.Provider>
  );
};

export const AdminConsumer = AdminContext.Consumer;

export default AdminContext;
