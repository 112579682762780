import React from "react";
import styled from "styled-components";

const StyledAddInput = ({
  small,
  label,
  required,
  type,
  name,
  id,
  placeholder,
  value,
  setAction,
  handleClick,
  color,
}) => {
  return (
    <Container color={color}>
      <h4 className="h4-header">{label}</h4>
      <div>
        <input
          onChange={(e) => {
            setAction(e.target.value);
          }}
          className="champ-input"
          type={type}
          placeholder={placeholder}
          value={value}
        />
        <button
          type="button"
          className="plus-btn"
          onClick={() => {
            if (value.replace(/\s+/g, "")) {
              handleClick();
              setAction("");
            }
          }}
        >
          +
        </button>
      </div>
    </Container>
  );
};

export default StyledAddInput;

const Container = styled.div`
  .h4-header {
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
    margin: 1em 0;
  }
  .champ-input {
    width: 200px;
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 0.35em;
  }
  .plus-btn {
    padding: 0.85em 1em;
    background-color: ${(props) => props.color};
    color: #fff;
    border-radius: 7px;
    margin-left: -12px;
    top: -1px;
    position: relative;
  }
  .error {
    font-size: 11px;
    color: red;
    font-weight: 600;
    margin-top: 0.1em;
    margin-left: 0.2em;
  }
`;
