import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Layout from "../layouts/DefaultLayout";
import Button from "../components/elements/Button";
import AdminContext from "../contexts/AdminContext";

const EditRvs = () => {
  const [scenarios, setScenarios] = useState([]);
  const [scenario, setScenario] = useState("");
  const [interventions, setInterventions] = useState([]);
  const [alphabet, setAlphabet] = useState("");
  const [alphabets, setAlphabets] = useState([]);
  const [intervention, setIntervention] = useState("");
  const [forceupdate, setForceupdate] = useState(0);
  const { REACT_APP_BACKEND_URL } = process.env;
  const token = localStorage.getItem("admin");
  const { admin } = useContext(AdminContext);
  const history = useHistory();

  const getrvAlphabets = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvAlphabets", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setAlphabets(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getrvScenarios = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvScenarios", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setScenarios(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getrvInterventions = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvInterventions", {
        method: "GET",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setInterventions(res);
    } catch (err) {
      console.log(err);
    }
  };

  const addrvAlphabet = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvAlphabet", {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: alphabet }),
      });

      const res = await response.json();
      if (res) {
        const arr = alphabets;
        arr.push({
          rvAlphabets_id: res.insertId,
          rvAlphabets_alphabet: alphabet,
        });
        setAlphabets(arr);
        setForceupdate(!forceupdate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addrvScenario = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvScenario", {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: scenario }),
      });

      const res = await response.json();
      if (res) {
        const arr = scenarios;
        arr.push({
          rvScenario_id: res.insertId,
          rvScenario_name: scenario,
        });
        setScenarios(arr);
        setForceupdate(!forceupdate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addrvIntervention = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvIntervention", {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: intervention }),
      });

      const res = await response.json();
      if (res) {
        const arr = interventions;
        arr.push({
          rvIntervention_id: res.insertId,
          rvIntervention_name: intervention,
        });
        setInterventions(arr);
        setForceupdate(!forceupdate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAlphabets = async (id) => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/rvAlphabet/" + id,
        {
          method: "DELETE",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (res.message) {
        const arr = alphabets.filter((item) => item.rvAlphabets_id !== id);
        setAlphabets(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteScenario = async (id) => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/rvScenario/" + id,
        {
          method: "DELETE",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (res.message) {
        const arr = scenarios.filter((item) => item.rvScenario_id !== id);
        setScenarios(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteIntervention = async (id) => {
    try {
      const response = await fetch(
        REACT_APP_BACKEND_URL + "/rvIntervention/" + id,
        {
          method: "DELETE",
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (res.message) {
        const arr = interventions.filter(
          (item) => item.rvIntervention_id !== id
        );
        setInterventions(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      admin?.role?.includes("Clients") &&
      !admin?.role?.includes("Rendez-vous")
    ) {
      history.push("/clients");
    } else if (
      admin?.role?.includes("Simulateur") &&
      !admin?.role?.includes("Rendez-vous")
    ) {
      history.push("/simulations");
    } else if (
      admin?.role?.includes("Réclamations") &&
      !admin?.role?.includes("Rendez-vous")
    ) {
      history.push("/complaints");
    } else if (
      admin?.role?.includes("Leads") &&
      !admin?.role?.includes("Rendez-vous")
    ) {
      history.push("/");
    }
  }, [admin]);

  useEffect(() => {
    getrvScenarios();
    getrvInterventions();
    getrvAlphabets();
  }, []);

  return (
    <Layout>
      <Container>
        <div className="card">
          <h4>Scénario Rendez-vous</h4>
          <div className="row input-row">
            <input
              className="input"
              name="scenario"
              id="scenario"
              type="text"
              placeholder="Scénario"
              value={scenario}
              onChange={(e) => setScenario(e.target.value)}
            />
            <Button
              handleClick={() => addrvScenario()}
              title="Ajouter"
              margin="0"
              radius="7px"
              padding="6px 22px"
            />
          </div>
          {scenarios.map((item, index) => (
            <div className="row rv-row" key={index}>
              <h5>Rendez-vous {item.rvScenario_name}</h5>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                onClick={() => deleteScenario(item.rvScenario_id)}
              >
                <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
              </svg>
            </div>
          ))}
        </div>
        <div className="card">
          <h4>Type d’intervention Rendez-vous</h4>
          <div className="row input-row">
            <input
              className="input"
              name="intervention"
              id="intervention"
              type="text"
              placeholder="Intervention "
              value={intervention}
              onChange={(e) => setIntervention(e.target.value)}
            />
            <Button
              handleClick={() => addrvIntervention()}
              title="Ajouter"
              margin="0"
              radius="7px"
              padding="6px 22px"
            />
          </div>
          {interventions.map((item, index) => (
            <div className="row rv-row" key={index}>
              <h5>{item.rvIntervention_name}</h5>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                onClick={() => deleteIntervention(item.rvIntervention_id)}
              >
                <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
              </svg>
            </div>
          ))}
        </div>
        <div className="card">
          <h4>Les Alphabets du matricule</h4>
          <div className="row input-row">
            <input
              className="input"
              name="alphabet"
              id="alphabet"
              type="text"
              placeholder="Alphabet"
              value={alphabet}
              onChange={(e) => setAlphabet(e.target.value)}
            />
            <Button
              handleClick={() => addrvAlphabet()}
              title="Ajouter"
              margin="0"
              radius="7px"
              padding="6px 22px"
            />
          </div>
          {alphabets.map((item, index) => (
            <div className="row rv-row" key={index}>
              <h5>{item.rvAlphabets_alphabet}</h5>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                onClick={() => deleteAlphabets(item.rvAlphabets_id)}
              >
                <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
              </svg>
            </div>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default EditRvs;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  background-color: #fff;
  border-radius: 20px;
  grid-gap: 2em;
  padding: 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  .card {
    box-shadow: 0 0 4px rgb(0 0 0 / 30%);
    border-radius: 20px;
    padding: 1.5em 2em;
    height: min-content;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 1em;
  }
  input {
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    min-width: 180px;
    margin-right: 1em;
    width: 100%;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    position: relative;
  }
  svg {
    fill: #f84b60;
    cursor: pointer;
  }
  .rv-row {
    border-bottom: 1px solid #dfe0eb;
  }
  .input-row {
    margin-bottom: 1.5em;
  }
  @media only screen and (max-width: 590px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    padding: 2em 0em;
    background: transparent;
    .card {
      padding: 1.5em 1em;
    }
  }
`;
